import React, { useEffect, useState } from 'react';
import { Navbar, NavLink, NavDropdown, Container, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../Assets/Images/logo.svg'

import icon2 from '../Assets/Images/user.svg'
import contact from '../Assets/Images/contact.png'
import SignModal from './Modals/SignModal';
import ForgotPwd from "./Modals/ForgotPwdModal";

const HomeHeader = () => {
  var result = React.createElement("img", { src: icon2, className: "me-2" });
  var str = React.createElement("span", {}, "Wallet");
  var parentdiv = React.createElement('div', {}, [result, str]);

  const [signin, setSignin] = useState(false)
  const [forgotpwd, setForgotPwd] = useState(false)


  const scrollto = (ele) => {
    // alert(ele)
    var node = document.getElementById(ele);
    console.log(node, "node");
    window.scrollTo(0, node.offsetTop);
  }


  return (
    <div id="homeheader">
      {forgotpwd && <ForgotPwd onDismiss={() => setForgotPwd(false)} />}
      {signin && <SignModal onDismiss={() => setSignin(false)} openmodal={() => setForgotPwd(true)} />}
      <Navbar expand="lg">
        <Container fluid className='px-xl-5 px-4'>
          <Navbar.Brand target="blank" href="/"><img src={logo} className="logo" /> </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="ms-auto">
              {/* <Link to="#about" >hgsadfhj</Link> */}
              <a className='me-3 me-md-4 link_cus' href="/home#about">About Us</a>
              {/* onClick={(e)=>{scrollto("about")}} */}
              <a className='me-3 me-md-4 link_cus' onClick={(e) => { scrollto("concept") }}>Concept</a>
              <a className='me-3 me-md-4 link_cus' onClick={(e) => { scrollto("opportunity") }}>Opportunity</a>

              <a href="/contactus" className='me-3 me-md-4'>
                {/* <div><img src={contact} className="me-2"/></div> */}
                Contact Us</a>

              {(!sessionStorage.Token || sessionStorage.Token == null || sessionStorage.Token == undefined || sessionStorage.Token == " ") && (!localStorage.Token || localStorage.Token == null || localStorage.Token == undefined || localStorage.Token == " ") ?
                <button className='btn btn-bord nav-link' onClick={() => setSignin(true)}>Sign in</button> :
                <a className="ps-2" href="/dashboard">
                  <div></div>
                  Dashboard</a>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar></div>
  )
}

export default HomeHeader 