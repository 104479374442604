
import React, { useState ,useEffect} from "react";
import { Modal,InputGroup, Form ,Button} from "react-bootstrap";
import img1 from '../../Assets/Images/tether.png'
import { getTokeninfo} from "../../Hooks/UseBackendAction/UseGetController"
import { backurl } from '../../config/env';
import {searchTokenValue} from '../../Hooks/UseValidation'




const SelectOutTokenModal = (props) =>{
const[selectouttoken, setselectouttoken] = useState(true)
const [tokeninfo, setTokeninfo] = useState([])
const [Search, setSearch]   = useState("")
const [isSearch, setisSearch] = useState(false)
const [SearchCoinData, SetSearchCoinData]      = useState([])

const handleChange = async (e) => {
        
    if((e.target.value).length > 0){
          const searchData = await searchTokenValue(tokeninfo,e.target.value);
         
                                   SetSearchCoinData(searchData)
                                  
                                   setisSearch(true)
    }else{
        setisSearch(false)
    }
  
  }

useEffect(() => {
 
    gettokeninfo();
    
}, [])

const gettokeninfo = async () => {
   
    const tokeninfo = await getTokeninfo();
        setTokeninfo(tokeninfo.data)
 }



    return(
        <div className="signinmodal ">
            <Modal  id="selectouttoken" show={selectouttoken} size="md" centered className="br-15 thememodal">
             
                <Modal.Body className=" br-15 small-input noshadow">
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                    <h6 className="my-4"> Select a token</h6>
                    <input onChange={(e)=>{setSearch(e.target.value);handleChange(e)}} className="form-control" placeholder="Search name or paste address" type="text"/>
                    <h6 className="mt-4">Token name</h6>
                    <ul className="token-lists">
                    {!isSearch ? 
                    <>
                    {tokeninfo && tokeninfo.map((item) =>
                    
                        <li   className={item.symbol === props.data  ? "disabled disableele" : ""} onClick={() => {props.selectoutToken(item.symbol,item.logoURI);props.onDismiss(false)}}><img src={backurl + `/Images/${item.logoURI}`} /> {item.name} </li>
                    )} </>:<>
                    {SearchCoinData && SearchCoinData.map((item) =>
                        <li  className={item.symbol === props.data  ? "disabled disableele" : ""} onClick={() => {props.selectoutToken(item.symbol,item.logoURI);props.onDismiss(false)}}><img src={backurl + `/Images/${item.logoURI}`} /> {item.name} </li>
                    )}</>
}
                       
                    </ul>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default SelectOutTokenModal