import React,{useState,useEffect} from "react";
import DataTable from 'react-data-table-component'
import {InputGroup, Form, Button} from 'react-bootstrap'
import { UsergetInfobyname,UserTransactions } from "../../Hooks/UseBackendAction/UseGetController";


const ViewTrans = (props) =>{

  const [records, setRecords] = useState([])
  // const [username,setusername] = useState(props.username);
  const {username} = props
 

  
  
  useEffect(() => {
    getdepositData();

}, [username])




const getdepositData = async () => {
       
    var UserInfo  =    await UsergetInfobyname(username);
    
    var TransactionInfo = await UserTransactions(UserInfo?.data?.userInfo?.userId);
    
    if(UserInfo?.success === true){
      setRecords(TransactionInfo.success ? TransactionInfo.data : [])
       
};
}

const columns = [
      {
        name: "S.No",
        selector: (data,index) => index+1,
        sortable: true
      },
     
        {
          name: "From",
          selector: (data) =>data.from,
          sortable: true
        },
        {
            name: "To",
            selector: (data) => data.to,
            sortable: true
            
          },
          {
            name: "Amount",
            selector: (data) => (data.amount / 10  ** 18).toFixed(5),
            sortable: true
            
          },
         
          {
            name: "Time",
            selector: (data) => (new Date(parseInt(data.timestamp)).toLocaleString("es-US")),
            sortable: true
          },
        ]

    return(
     
        <div className="viewuser">
        
              <div className='container container-theme'>
                <div className="d-flex jc-between small-input noshadow">
                   <Button variant="link" className="text-dark nounder noshadow-on-focus" onClick={props.goto}> 
                    <h4 className="flex-shrink-0 mb-0"><span className="fa fa-chevron-left f-16 me-2"></span>View user Transaction</h4>
                    </Button>
                    
                </div>
               
                <div className="mt-4 viewdata">
                    <DataTable  columns={columns} data={records} pagination/>
                </div>
                
                </div>
        </div> 
        

    )
}

export default ViewTrans