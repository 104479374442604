import React,{useState,useEffect} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';
import coin3 from '../../Assets/Images/coin3.png'
import tether from '../../Assets/Images/tether.png'
import busd from '../../Assets/Images/busd.png'
import logobg from '../../Assets/Images/logobg.png'
import BuyModal from '../Modals/BuyModal';
import Web3Modal from '../Modals/Web3Modal';
import UpgradeModal from '../Modals/Upgradeplan';
import * as GetController from "../../Hooks/UseBackendAction/UseGetController"
import { Button } from 'react-bootstrap';
import {style,iconTheme,position} from '../../Hooks/UseToast'
import {ADMIN, backurl} from "../../config/env";



    


const AssetDetails = () => {
    const [buy , setBuy] = useState(false)
    const [connect , setConnect] = useState(false)
    const [Upgrade , setUpgrade] = useState(false)
    const [tokenAddress , settokenAddress] = useState(ADMIN)
    const [UserName , setUserName] = useState(sessionStorage.getItem("username") || localStorage.getItem("username"))
    const [RegisterIngo,setRegisterIngo] = useState({})
    const [isuserCreate,setisuserCreate] = useState(false)
    const[coindata,setcoindata] = useState([])
    const [tokeninfo, setTokeninfo] = useState([])

     
    useEffect(() => {
        setUserName()
        getdepositData();
        gettokeninfo();
        // showLoader();
       
    }, [])
    const showLoader = () => {
        document.getElementById("loader").classList.add("show")
           document.getElementsByTagName("body")[0].style.overflow = "hidden"
     }
   
     const hideLoader = () => {
       document.getElementById("loader").classList.remove("show")
       document.getElementsByTagName("body")[0].style.overflow = "unset"
   
    }
    const copyText = (a, b) => {
        toast.success("Address Copied", {
            position:position.position,
            style:style,
            iconTheme: iconTheme,
        }
        )
    
      }
    const getdepositData = async () => {
        document.getElementById("balance").classList.remove("blur")
        var UserInfo  =    await GetController.UsergetInfobyname(UserName);
        
        if(UserInfo?.success === true){
            setisuserCreate(true)
            var tokenid = await Promise.all(await UserInfo?.data?.balance.map(async(item,index) =>{
                var dat= await GetController.UsetokeninfoById(index)
              
                dat['data']['Balance'] = item
                return dat['data']
                
              }  ))
    
    
        setcoindata(tokenid)
        // hideLoader();
        }else{
            document.getElementById("balance").classList.add("blur")
        }
        var deposittoken = await GetController.getdeposittoken();
        var RegisterIngo = await GetController.UsergetRegisterInfo(UserName)
       
                                 setRegisterIngo(RegisterIngo?.data)
        
        
        // settokenAddress(deposittoken?.data)
       
};
const gettokeninfo = async () => {
   
   
        const tokeninfo = await GetController.getTokeninfo();

        setTokeninfo(tokeninfo.data)
        
    }


    return(
        <>
         {connect && <Web3Modal registerinfo={RegisterIngo} onDismiss={() => setConnect(false)} />}
        {buy && <BuyModal registerinfo={RegisterIngo} onDismiss={() => setBuy(false)} />}
        {Upgrade && <UpgradeModal  onDismiss={() => setUpgrade(false)} />}

        
         <div className='container container-theme logocont'>
            <img src={logobg} className="logobg"/>
                  <div className='d-flex jc-between'>
                      <h5 className='fw-400 bluetxt'>Receive <span className='fw-700'>Currency</span></h5>

                      {isuserCreate ?
                      <button className='btn btn-theme' onClick={() => setUpgrade(true)}>Upgrade Plan</button>:
                      <button className='btn btn-theme' onClick={() => setBuy(true)}>Buy Plan</button>}

                  </div>  
                    <div className='row mb-5'>
                        <div className='col-xl-7 col-lg-6'>
                        <div className='whitebox'>
                        <Scrollbars style={{height: 100}}>
                                <div className='d-flex jc-around h-100 align-items-center reffincome mobilejc'>
                                {tokeninfo && tokeninfo.map((item) =>
                                    <div className='d-flex mx-3 '>
                                       <img src={backurl+`/Images/${item.logoURI}`} />
                                        <div className='ms-2'>
                                             <p className='mb-0 fw-500'>{item.name}</p>
                                        <p className='graytxt f-14 fw-500 mb-0'>({item.symbol})</p>
                                        </div>
                                    </div>
                                )}
                                    
                                   
                                </div>
                                </Scrollbars>
                            </div>
                            <div className='whitebox'>
                            <button className='btn btn-theme' onClick={() => setConnect(true)}>Connect</button>
                                <Scrollbars style={{height: 400}}>
                                    <p className='boxhead graytxt pt-4'>Scan QR Code</p>
                                    <div className='text-center mt-5'>
                                        {/* <img src={qr} /> */}
                                        <QRCode size="150" value={tokenAddress} />
                                        <p className='mt-2'>TMC Coin(BEP20)</p>
                                    </div>

                                   <div className='row'>
                                    <div className='col-md-8 m-auto'>
                                    <div className='small-input noshadow qrinput d-flex gap-3 mt-4'>
                                      <input type="text"value={tokenAddress} readOnly className='w-full'/>
                                      <CopyToClipboard text={`${tokenAddress}`} onCopy={() => copyText('invite link', `${tokenAddress}`)}>


                                      <Button variant='link'><span className='fa fa-copy'></span></Button>
                                      </CopyToClipboard>
                                    </div>
                                    </div>
                                   </div>
                                </Scrollbars>
                            </div>
                        </div>
                        <div className='col-xl-5 col-lg-6'>
                            <div className='whitebox walletbalance' id="balance">
                            <div className='d-flex jc-between'>
                            <p className='fw-400 bluetxt mb-0'>Wallet <span className='fw-700'>Balance</span></p>
                           
                            </div>

                                <Scrollbars style={{height: 230}}>
                                {coindata.map((item)  =>
                                
                                    <div className='d-flex jc-between w-full mb-4 mt-4'>
                                        <div className='ms-2'>
                                             <p className='mb-0 fw-500'>{item.name}</p>
                                            <p className='graytxt f-14 fw-500 mb-0 aqua'>{parseFloat(item.Balance / 10  ** 18).toFixed(5)} {item.symbol}</p>
                                        </div>
                                        <img src={backurl+`/Images/${item.logoURI}`} />
                                    </div>
                                )}
                                    
                                    
                              </Scrollbars>
                            </div>
                     
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='whitebox '>
                                        <Scrollbars style={{height: 230}}>
                                        {/* <p className='boxhead graytxt upper'>Notes</p>
                                            <p className='f-14 mb-0 fw-400'>
                                           
                                            Deposit tokens to the Wallet by scanning the QR code.
                                            (OR)
                                            Copy the address below the QR code to deposit tokens into your Wallet.


                                            </p> */}
                                            <p className='boxhead graytxt upper'>Support </p>
                                            <p className='f-14 mb-0 fw-400'>
                                           
                                        
                                          If Your deposited Crypto / Token is not reflected in your account. please submit the transcation hash below


                                           </p>
                                            <input type="text" placeholder="Enter transaction hash" className='w-full'/>
                                            <button className='btn btn-theme' onClick={() => setConnect(true)}>Verify</button>
                                        </Scrollbars>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default AssetDetails