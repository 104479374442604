import React, { useState,useEffect} from 'react';
import {InputGroup,Form,OverlayTrigger,Button,Popover} from 'react-bootstrap'

import logo from '../Assets/Images/logo.svg'
import burger from '../Assets/Images/burger.png'
import prof from '../Assets/Images/prof.png'
import coin1 from '../Assets/Images/coin3.png'
import { useDispatch, useSelector } from "react-redux";
import {logoutUser} from "../Hooks/UseBackendAction/UseCreateUser.js"
import * as config from "../config/env";
import toast, { Toaster } from 'react-hot-toast';
import {sleep} from "../Hooks/UseBackendAction/UseGetController"
import { useNavigate } from 'react-router-dom';
import * as GetController from "../Hooks/UseBackendAction/UseGetController"
import {backurl} from "../config/env";
import {style,iconTheme,position} from '../Hooks/UseToast'



const Header = (props) =>{
    const[sidebar, setSidebar] = useState(true)
    const Reduxstate = useSelector(state => state.reducer)
    const [username,setName] = useState(sessionStorage.getItem("username") ? sessionStorage.getItem("username") : localStorage.getItem("username"))
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [userInfo, setuserInfo] = useState({})
   
    


    const headfunc = () =>{
        setSidebar(!sidebar)
        props.newfunc(sidebar)
    }
    
  useEffect(() =>{
    

    if ((!sessionStorage.Token || sessionStorage.Token == null || sessionStorage.Token == undefined ||sessionStorage.Token == " " ) && ( !localStorage.Token || localStorage.Token == null || localStorage.Token == undefined ||localStorage.Token == " ")) {
      toast.error("Go to Login !",{
        position:position.position,
        style:style,
        iconTheme: iconTheme,
      })
      navigate("/")
    }
   else { getuserData()
   }
  

   },[])

  const getuserData = async() => {

        
    var UserInfo  =    await GetController.UsergetInfobyname(username);
    setuserInfo(UserInfo.data)
}

   const onLogoutClick = async() => {
    dispatch(logoutUser());
  };
    return(
        <div id="header">
           
            <div className= {sidebar ? 'd-flex jc-between align-items-center hamexp' : 'd-flex jc-between align-items-center hamcomp'}>
                <div className='d-flex align-items-center'>

                    <button onClick={() => headfunc()} className="hamburger">
                        <img src={burger} />
                    </button>
                    <a href="/" target="blank"><img src={logo} className="logo d-none-767"/> </a>
                    {/* <img src={logo} className="logo d-none-767"/> */}
                    <img src={coin1} className="mobilelogo d-block-767"/>

                    {/* <InputGroup className="ms-5 search">   
                        <Form.Control
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon1" />
                    <InputGroup.Text id="basic-addon1"><span className='fa fa-search'></span></InputGroup.Text>
                </InputGroup> */}
                </div>
                <div className='pe-4'>
                    <span>Hi, {username} </span>
      <OverlayTrigger
      rootClose={true}
          trigger="click"
          placement="bottom"
          
          overlay={
            <Popover id="popover-positioned-bottom" trigger="focus"   >
              <Popover.Body>
                <Button variant='link' className='nounder text-dark' onClick={()=>onLogoutClick()}>Logout</Button>
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="secondary" className='transbtn profimg'>
            {/* <img src={prof} /> */}
            <img src={backurl+`/Images/${userInfo?.logoURI}` }onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src=prof;
  }}/>
            </Button>
        </OverlayTrigger>
                </div>
            </div>
        </div>
    )
}

export default Header