import React,{useState,useEffect} from 'react';
import {Button} from 'react-bootstrap'
import {getsettinginfo} from "../Hooks/UseBackendAction/UseGetController"
import {Link} from 'react-router-dom'
import logo from '../Assets/Images/logo.svg'
import { NewSuscriperContact } from '../Hooks/UseBackendAction/UseCreateUser.js';
import { validateSubscriper } from '../Hooks/UseValidation';
const Footer = () =>{
    const [settingData, setsettingdata] = useState([])
    const [errors, seterrors] = useState({})
    const [email,setemail] = useState("")
    const scrollto = (ele) =>
    {
      // alert(ele)
      var node = document.getElementById(ele);
      console.log(node,"node");
      window.scrollTo(0, node.offsetTop);
    }
    

    const onSubmit = async (e) => {

        e.preventDefault();
        const UserData = {
            email: email,
    };
        
        const { errors, isValid } = await validateSubscriper(UserData);
        if (!isValid) {
            seterrors(errors)
        }
        else {
           await NewSuscriperContact(UserData)
           
        }

       

    };
    useEffect(() => {
        gettokeninfo()
       
    }, [])
    const gettokeninfo = async () => {
   
        const settingData = await getsettinginfo();
        setsettingdata(settingData.data)
    }
    return(

        <div className='footer_outer'>

        
        <div id="footer">
        <div className='container'>
            <div className='pt-5'>
              
                <div className='row'>
                    <div className='col-12 col-xl-3 pr-lg-5'>
                        <img src={logo} className="img-fluid" />
                        <p className='footer_text mt-3'>TMC is a Artificial Intelligence Software System running through Multi Mercantile System.</p>
                    </div>
                    <div className='col-12 col-lg-12 col-xl-8 offset-xl-1'>
                        <div className='row'>
                        <div className='col-12 col-lg-4 link_col'>
                        <p className='footer_title'>Quick Links</p>
                        <ul className='ps-0 footer_menu'>
                            <li>
                                <Link to="/">Home</Link>
                                <Link to="/" onClick={(e)=>{scrollto("about")}}>About</Link>
                                <Link to="/" onClick={(e)=>{scrollto("concept")}}>Concept</Link>
                                <Link to="/" onClick={(e)=>{scrollto("opportunity")}}>Opportunity</Link>
                                <a target='blank' href='https://bscscan.com/token/0x9FbF37fFAcfd1b0bCe09cc9bF7F1Ecb76DAD7E0A'>TMC coin's bscscan link</a>

                            </li>
                        </ul>
                    </div>
                    <div className='col-12 col-lg-4 link_col'>
                        <p className='footer_title'>Company</p>
                        <ul className='ps-0 footer_menu'>
                            <li>
                                <Link to="/contactus">Contact Us</Link>
                                <Link to="/">Terms of Use</Link>
                                <Link to="/">Privacy Policy</Link>
                                <Link to="/">Legal</Link>

                            </li>
                        </ul>
                    </div>
                    <div className='col-12 col-lg-4'>
                        <p className='footer_title'>Signup to our Newsletter</p>
                        <form onSubmit={onSubmit} className='form_contact'>
                        <div className='row footer_news_row'>
                            <div className='col-8 pr-0'>
                            <div className="form-group">
                    <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text pe-0" id="basic-addon3">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                    </div>
                    <input type="text" value={email}
                    onChange={(e) => { setemail(e.target.value)}} className="form-control" placeholder="Youl Mail Id" aria-label="Mail" aria-describedby="basic-addon3" />
                    </div>
                    <span className="text-danger f-12 d-block text-left">{errors.email}</span>
                    </div>
                            </div>
                            <div className='col-4 ps-0'>
                            <button className='btn btn-theme ms-auto'>Subscribe</button>
                         
                                
                            </div>
                        </div>
                        </form>
                        <ul className='list_inline_social ps-0 mb-0 mt-2'>
                                <li className='li_inline_social'>
                                    <a href={settingData && settingData[23] && settingData[23].settingvalue}>
                                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                    </a>
                                </li>                             
                                <li className='li_inline_social'>
                                    <a href={settingData && settingData[24] && settingData[24].settingvalue}>
                                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li className='li_inline_social'>
                                    <a href={settingData && settingData[22] && settingData[22].settingvalue}>
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                    </a>
                                </li>

                            </ul>
                    </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        </div>
        <hr className='hr_white' />
        <div id="footer2" className='pb-3'>
        <div className='container pb-0'>
        <p className='footer_text text-center mb-0'>Copright © 2022 - TRADEMAX</p>
        </div>
        </div>
        </div>
    
    )
}

export default Footer