import React, { useState,useEffect} from "react";
import { Modal} from "react-bootstrap";
import QRCode from "react-qr-code";

import qr from "../../Assets/Images/qr.png"
import { Check2FACode } from "../../Hooks/UseBackendAction/UseCreateUser.js";
import { UsergetInfobyname } from "../../Hooks/UseBackendAction/UseGetController";

const GoogleAuth = (props) =>{
  const [googleAuth, setGoogleAuth] = useState(true)
  const [userName,setuserName] = useState(sessionStorage.getItem("username") || localStorage.getItem("username"))
  const [authcode,setauthcode] = useState("")
  const [key,setKey] = useState("")
  const [box1,setbox1] = useState("")
  const [box2,setbox2] = useState("")
  const [box3,setbox3] = useState("")
  const [box4,setbox4] = useState("")
  const [box5,setbox5] = useState("")
  const [box6,setbox6] = useState("")

  useEffect(() => {
    getuserData();
  },[]);

const getuserData = async() => {
   var UserInfo  =    await UsergetInfobyname(userName);
   if(UserInfo.success === true){
         var key = UserInfo?.data?.authcode.replace(/\W/g, '').toLowerCase();
         setKey(key)
         var link = "otpauth://totp/{{NAME}}?secret={{KEY}}";
         var name = "TMC"
         var codeToQr = link.replace(/{{NAME}}/g, name).replace(/{{KEY}}/g, key)
         setauthcode(codeToQr)
    }
};


    let tabChange = (val) => {
      if(5 >= val ===true){
      
        let ele = document.querySelectorAll('input.otp');
        
        if(ele[val-1].value != ''){
          ele[val].focus()
        }else if(ele[val-1].value == ''){
          ele[val-2].focus()
        }  
      } 
     }

     const on2FASubmit = async () => {

    
    
      const User2fAData = {
        // headers : {authorization: `Bearer ${accessToken}`},

        user_name: userName,
        key: key,
        token:box1 + box2 + box3 + box4 + box5 + box6
          
      };
     
      await Check2FACode(User2fAData)

  };

    return(
        <div className="signinmodal editprofile">
                
            <Modal centered show={googleAuth} size="md" className="br-15 thememodal">
               
                <Modal.Body className="br-15"> 
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
               <h4><b>2FA Code</b></h4>
                <div className='otp-log'>
                 
                    <p className='f-12 text-center mt-5'>Scan the QR Code in the Authenticator app then enter the code that you see in the app in the text field and click confirm</p>

                
                    <div className='text-center '>
                                       
                                        <QRCode size="150" value={authcode} />
                                        {/* <p className='mt-2'>TMC Coin(TRC20)</p> */}
                                    </div>
          <p className="f-14 text-center mt-5">Enter 2F code</p>
            
            <form action="" className="mt-2 mb-4 justify-content-center confirm">
              <input className="otp" type="text" onChange={(e) => { setbox1(e.target.value) }} onKeyUp={() => tabChange(1)} maxlength="1" />
              <input className="otp" type="text" onChange={(e) => { setbox2(e.target.value) }} onKeyUp={() => tabChange(2)} maxlength="1" />
              <input className="otp" type="text" onChange={(e) => { setbox3(e.target.value) }} onKeyUp={() => tabChange(3)} maxlength="1" />
              <input className="otp" type="text" onChange={(e) => { setbox4(e.target.value) }} onKeyUp={() => tabChange(4)} maxlength="1" />
              <input className="otp" type="text" onChange={(e) => { setbox5(e.target.value) }} onKeyUp={() => tabChange(5)} maxlength="1" />
              <input className="otp" type="text" onChange={(e) => { setbox6(e.target.value) }} onKeyUp={() => tabChange(6)} maxlength="1" />
              
            </form>
           <div className="text-center my-5">
        
            <button className="btn btn-theme" onClick={()=> on2FASubmit()}>Confirm</button>
           </div>
       
                </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default GoogleAuth