export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const USER_LOADING = 'USER_LOADING';

export const USER_ADD = 'USER_ADD';

export const USER_UPDATE ='USER_UPDATE'



