import React,{useState,useEffect} from "react";
import DataTable from 'react-data-table-component'
import {InputGroup, Form} from 'react-bootstrap'
import { getAlluserInfo } from "../../Hooks/UseBackendAction/UseGetController";
import {searchedValue} from "../../Hooks/UseValidation"
import Documents from "./Modals/DocumentModal";
import { propTypes } from "react-bootstrap/esm/Image";
import { backurl } from "../../config/env";
import profimg from '../../Assets/Images/profile.png'
import { Link } from 'react-router-dom';
import ViewTrans from "./ViewTransaction";

const ViewUser = () =>{

  const [records, setRecords] = useState([])
  const [Search, setSearch]   = useState("")
  const [isSearch, setisSearch] = useState(false)
  const [SearchCoinData, SetSearchCoinData]      = useState([])
  const[document, setDocument] = useState(false)
  const [Currentrecords, setCurrentRecords] = useState({})

  const [username, setUsername] = useState("");
  const [userid, setUserid] = useState("");


  const[showtrans, setShowTrans] = useState(true)
  useEffect(() => {
    getData();

}, [])


const handleChange = async (e) => {
        
  if((e.target.value).length > 0){
        const searchData = await searchedValue(records,e.target.value);
       
                                 SetSearchCoinData(searchData)
                                
                                 setisSearch(true)
  }else{
      setisSearch(false)
  }

}

const showData = async (name,id) =>
{
  setUsername(name);
  setUserid(id);
}
const getData = async () => {
  var data = await getAlluserInfo();
  
  setRecords(data?.data ? data?.data : [])
};
    const columns = [
      { 
        name: "S.No",
        selector: (data,index) => index+1,
        sortable: true
      },
      {
        name: "User Image",
       
        sortable: true,
        cell: (data) => 
        { return (
          <img
              src={backurl + `/Images/${data.logoURI}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=profimg;
              }}
              width={30} className="b-50"
              alt='form'
          />
      )}
      },
        {
          name: "User Name",
          selector: (data) => data.user_name,
          sortable: true
        },
        {
            name: "User ID",
            selector: (data) => data.userInfo.userId,
            sortable: true
          },
          {
            name: "Referrer ID",
            selector: (data) => (data.userInfo.originalReferrer),
            sortable: true
          },

          {
            name: "Referral Count",
            selector: (data) => data.userInfo.directCount,
            sortable: true
          },
          
          {
            name: "Binary Income",
            selector: (data) => (data.userInfo.binaryIncome / 10 ** 18).toFixed(3),
            sortable: true
          },
          {
            name: "Total Income",
            selector: (data) => (data.userInfo.gainAmount / 10 ** 18).toFixed(3),
            sortable: true
          },
          
          {
            name: "KYC",
            button: true,
            cell: (data) => (
           data.KYCFile.length > 0 ?
          <button
            type="button"
            class="btn btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            onClick={() => { editRecord(data); setDocument(true) }}>
            Open
          </button>:
          <button
          type="button"
          class="btn btn-theme"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          disabled>
          No KYC
        </button>
          )
          },
          {
            name: "KYC Status",
            button: true,
            cell: (data) => (
           data.isKYCVerify === "false" ?
          <button
            type="button"
            class="btn btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            >
            Decline
          </button>:
           data.isKYCVerify === "true" ? 
          <button
          type="button"
          class="btn btn-theme"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          >
          Accept
        </button>:
        <button
        type="button"
        class="btn btn-theme"
        data-bs-toggle="modal"
        data-bs-target="#myModal"
        disabled>
        None
      </button>
          )
          },
          {
            name: "Transaction",
            button: true,
            cell: (data) => (
              // <Link to={`/usertransaction/${data.user_name}`}>     
             <button onClick={() => (setShowTrans(!showtrans), showData(data.user_name, data.userInfo.userId))}
               type="button"
               class="btn btn-theme"
               data-bs-toggle="modal"
               data-bs-target="#myModal"
               className="fa fa-eye transicon"
               >
              
             </button>
            // </Link>
             )
          }
    ]
    
    const editRecord = async (record) => {
      setCurrentRecords(record)
  }
  const gotoViewuser = () =>{
       setShowTrans(true)
  }
    return(
     <>
      <div className={showtrans ? "d-none" : ""}>
          <ViewTrans username={username} userid={userid} goto={() => gotoViewuser()}/>
      </div>
      <div className={showtrans ? "viewuser" : "viewuser d-none"}>
          {document && <Documents record={Currentrecords} onDismiss={() => setDocument(false)} />}
              <div className='container container-theme'>
                <div className="d-flex jc-between small-input noshadow">
                    <h4 className="flex-shrink-0 mb-0">View user</h4>
                    <InputGroup className="justify-content-end">
                        <InputGroup.Text id="basic-addon1"><span className="fa fa-search"></span></InputGroup.Text>
                        <Form.Control
                        onChange={(e)=>{setSearch(e.target.value);handleChange(e)}}
                        placeholder="Search by Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>

               

                {!isSearch ?
                <div className="mt-4 viewdata">
                    <DataTable  columns={columns} data={records} pagination/>
                </div>:
                <div className="mt-4 viewdata">
                <DataTable  columns={columns} data={SearchCoinData} pagination/>
            </div>}

                
                </div>
      </div> 
      </>

    )
}

export default ViewUser