import React,{useState,useEffect} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import dollar from '../../Assets/Images/dollar.png'
import premium from '../../Assets/Images/premium.png'
import basic from '../../Assets/Images/Basic.png'
import arrow from '../../Assets/Images/arrow.png'
import laptick from '../../Assets/Images/lap-tick.png'
import usertick from '../../Assets/Images/user-tick.png'
import coin1 from '../../Assets/Images/coin1.png'
import coin2 from '../../Assets/Images/coin2.png'
import coin3 from '../../Assets/Images/coin3.png'
import EditProf from '../Modals/EditProfModal';
import UpDocument from '../Modals/UpDocModal';
import * as GetController from "../../Hooks/UseBackendAction/UseGetController"
import {frontendurl} from '../../config/env';
import { Link } from "react-router-dom";
import Claim from '../Modals/ClaimModal';
import {backurl} from "../../config/env";
import CopyToClipboard from "react-copy-to-clipboard";
import {style,iconTheme,position} from '../../Hooks/UseToast'
import toast, { Toaster } from 'react-hot-toast';
import { Button } from 'react-bootstrap';



const Income = (props) => {
    const[updocument , setUpDocument] = useState(false)
    const[editprofile , setEditProfile] = useState(false)
    const[UserId , setUserId] = useState(sessionStorage.getItem("userId"))
    const[UserName , setUserName] = useState(sessionStorage.getItem("username") || localStorage.getItem("username"))
    const[ReferrerId , setReferrerId] = useState(null)
    const[UserInfo,setUserInfo] = useState({})
    const[ReferrerInfo,setReferrerInfo] = useState({})
    const[RoyalIncome,setRoyalIncome] = useState(0)
    const[BinaryIncome,setBinaryIncome] = useState(0)
    const[BinaryLeftIncome,setBinaryLeftIncome] = useState(0)
    const[BinaryRightIncome,setBinaryRightIncome] = useState(0)
    const[tokeninfo,setTokeninfo] = useState([])
    const[userfname,setuserfname] = useState(sessionStorage.getItem("userfname"))
    const[userlname,setuserlname] = useState(sessionStorage.getItem("userlname"))
    const[active, setActive] = useState(0)
    const[coindata,setcoindata] = useState([])
    const[TotalTMC,setTotalTMC] = useState(0)
    const[landmarkeligibility,setlandmarkeligibility] = useState(0)
    const[superlandmarkeligibility,setsuperlandmarkeligibility] = useState(0)

    const [claim , setClaim] = useState(false)
    const [tmcprice, settmcprice] = useState(0)
    const [DailyLimit,setDailyLimit] = useState(0)
    
    const Menus = (data) =>{
        props.Menu(data)
        setActive(data)
    }

    const copyText = (a, b) => {
        toast.success("Refferal Link Copied", {
            position:position.position,
            style:style,
            iconTheme: iconTheme,
        }
        )
    
      }
      const showLoader = () => {
        document.getElementById("loader").classList.add("show")
           document.getElementsByTagName("body")[0].style.overflow = "hidden"
     }
   
     const hideLoader = () => {
       document.getElementById("loader").classList.remove("show")
       document.getElementsByTagName("body")[0].style.overflow = "unset"
   
    }
    useEffect(() => {
        getuserData();
        // showLoader();
      },[UserId]);
     
    const getuserData = async() => {
        const tokeninfo = await GetController.getTokeninfo();
        var TokenPrice = await GetController.UseGEtTokenPrice(tokeninfo.data[1].symbol);
        settmcprice(TokenPrice.data)
       
        await GetController.UserLevelRewards(UserId);
      
        await GetController.UserBinaryRewards(UserId);
        
        
        var UserInfo  =    await GetController.UsergetInfobyname(UserName);
        const settingdata = await GetController.getsettinginfo();
        
        setlandmarkeligibility(Number(settingdata.data[1].settingvalue))
        setsuperlandmarkeligibility(Number(settingdata.data[2].settingvalue))
      
       
      
        
if(UserInfo.success === false){
           
            document.getElementById("profile")?.classList.add("blur")
            document.getElementById("income")?.classList.add("blur")
            document.getElementById("balance")?.classList.add("blur")
            document.getElementById("Security")?.classList.add("blur")
            document.getElementById("refferal")?.classList.add("blur")
            document.getElementById("news")?.classList.add("blur")
            document.getElementById("referralid")?.classList.add("blur")
            
}   
        else{
            setUserInfo(UserInfo.data)
            setReferrerId(UserInfo?.data?.userInfo?.referrerID)
            var ReferrerInfo = await GetController.UserreferrergetInfo(UserInfo?.data?.userInfo?.referrerID)
            setReferrerInfo(ReferrerInfo?.data)
            // var totalGainTMC = await GetController.UseUSDValue(UserInfo?.data?.userInfo?.gainAmount / 10 ** 18);
            // setTotalTMC(totalGainTMC.toFixed(5));

            var royalincome =  await GetController.UserRoyalIncome(UserId);
                               setRoyalIncome(Number(royalincome?.RoyalIncome))
            var BinaryincomeValue = await GetController.UserBinaryIncome(UserId);
            console.log("BinaryincomeValue",BinaryincomeValue.leftIncome);
                                    setBinaryIncome(BinaryincomeValue.BinaryIncome || 0)
                                    setBinaryLeftIncome(parseFloat( BinaryincomeValue?.leftIncome) )
                                    setBinaryRightIncome(parseFloat(BinaryincomeValue?.rightIncome))
            var tokenid = await Promise.all(await UserInfo?.data?.balance.map(async(item,index) =>{
                var dat= await GetController.UsetokeninfoById(index)
              
                dat['data']['Balance'] = item
                return dat['data']
                
              }  ))
 

        setcoindata(tokenid)

        var dailymint = await GetController.getDailymintdata(UserInfo?.data?.userInfo?.userId)
        setDailyLimit(dailymint?.data)
        console.log("dailymint",dailymint);
        hideLoader();
        
        }};

 
   
   
    return(
        <>
        
        
        {claim && <Claim record={UserInfo} onDismiss={() => setClaim(false)}/>}
        {editprofile && <EditProf record={UserInfo} onDismiss = {() => setEditProfile(false)} />}
        {updocument && <UpDocument onDismiss={() => setUpDocument(false)} />}

         <div className='container container-theme income'>


         <div className="d-sm-flex jc-between">
            
            
                   
                    <h5 className='fw-400 bluetxt'> <span className='fw-700'>{UserName}'s  </span> Earnings</h5>
                    {/* <Link to="/asset"> */}
                       <button className='btn btn-theme px-2' onClick={() => setClaim(true)}>Claim Earned</button>
                    {/* </Link> */}
                    </div>

                   
                            <div className='whitebox' id="income">
                               <div className='d-flex jc-between mb-3'>
                                 <p className='boxhead graytxt mb-0'><b> Total Referral Income Earned </b> </p>
                                
                                 {/* <button className='btn btn-theme' >Refresh</button> */}
                                 </div>
                                <div className='d-flex jc-around text-center reffincome'>
                                    <div>
                                        <p className='graytxt mb-0'>Binary Income</p>
                                        <img src={dollar} />
                                        <h5 className='aqua'>$ {((parseFloat(UserInfo?.userInfo?.binaryIncome) * tmcprice) / 10 ** 18).toFixed(5)}</h5>
                                        <p className='graytxt f-14'>TMC (In USDT)</p>
                                    </div>
                                    {/* <div>
                                        <p className='graytxt mb-0'>Royal Income</p>
                                        <img src={arrow} />
                                        <h5 className='aqua'>{RoyalIncome}</h5>
                                        <p className='graytxt f-14'>TMC coins</p>
                                    </div> */}
                                    <div>
                                        <p className='graytxt mb-0'>Total Income</p>
                                        <img src={arrow} />
                                        <h5 className='aqua'>$ {UserInfo.userInfo?.gainAmount / 10 ** 18}</h5>
                                        <p className='graytxt f-14'>USD coins</p>
                                    </div>
                                    <div>
                                        <p className='graytxt mb-0'>Your Plan</p>
                                        {UserInfo?.userInfo?.currentPlan == "0" ?
                                         <img src={basic} /> :
                                        <img src={premium} />}
                                      
                                        <h5 className='aqua'>{UserInfo?.userInfo?.currentPlan =="0"? "Basic" : "Prime"}</h5>
                                        <p className='graytxt f-14'>User</p>
                                    </div>
                                </div>
                            </div> 
                       
                     
                            <div className='row'>
                                

                                <div className='col-lg-4 col-md-12 my-2'>
                                    <div className='whitebox' id="Security">
                                     
                                        <p className='boxhead graytxt'><b>Circle Income</b></p>
                                        <ul className='security mt-4'>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Total Earned Income</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{(parseFloat(UserInfo?.userInfo?.circleIncome)/10**18).toFixed(5)}</button>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Total Direct Referred Users</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{((UserInfo?.userInfo?.directCount))}</button>
                                                </div>
                                            </li>
                                          
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-8 col-md-12 my-2'>
                                    <div className='whitebox' id="Security">
                                     
                                        <p className='boxhead graytxt'><b>Binary Income</b></p>
                                        <ul className='security mt-4'>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Total Earned Income</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{(parseFloat(UserInfo?.userInfo?.binaryIncome)/10**18).toFixed(5)} TMC</button>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='d-flex me-sm-3 me-2'>
                                                    <img src={usertick} />
                                                    <div>
                                                        <h5 className='mb-1'>Current Total Income on Left</h5>
                                                    <button className='btn btn-theme px-2 badge badge-theme'>{(parseFloat(BinaryLeftIncome && BinaryLeftIncome || 0)).toFixed(5)}</button>
                                                    </div>
                                                </div>
                                                <div className='d-flex  me-sm-3 me-2'>
                                                    <img src={usertick} />
                                                    <div>
                                                        <h5 className='mb-1'>Current Total Income on Right</h5>
                                                    <button className='btn btn-theme px-2 badge badge-theme'>{(parseFloat(BinaryRightIncome && BinaryRightIncome|| 0)).toFixed(5)}</button>
                                                    </div>
                                                </div>
                                               
                                              
                                            </li>
                                            <li>
                                            <div className='d-flex'>
                                                    <img src={usertick} />
                                                        <div>
                                                            <h5 className='mb-1'>Remaining Paired Amount on Left</h5>
                                                        <button className='btn btn-theme px-2 badge badge-theme'>{UserInfo?.userInfo?.remainingBinary.length > 0 ? (parseFloat(UserInfo?.userInfo?.remainingBinary[0] || 0 )/10**18 ).toFixed(5)  : "0"}</button>
                                                        </div>
                                                </div>
                                                <div className='d-flex '>
                                                     <img src={usertick} />
                                                    <div>
                                                        <h5 className='mb-1'>Remaining Paired Amount  on Right</h5>
                                                    <button className='btn btn-theme px-2 badge badge-theme'>{UserInfo?.userInfo?.remainingBinary.length > 0 ? (parseFloat(UserInfo?.userInfo?.remainingBinary[1] || 0 )/10**18 ).toFixed(5)  : "0"}</button>
                                                    </div>
                                                </div>
                                            </li>
                                          
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 my-2'>
                                    <div className='whitebox' id="Security">
                                     
                                        <p className='boxhead graytxt'><b>Level Income</b></p>
                                        <ul className='security mt-4'>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Total Earned Income</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{(parseFloat(UserInfo?.userInfo?.levelIncome)/10**18).toFixed(5)}</button>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Level Reached so far</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{((UserInfo?.userInfo?.currentLevel))}</button>
                                                </div>
                                            </li>
                                           
                                          
                                        </ul>
                                    </div>
                                </div>
                               
                           
                                <div className='col-lg-6 col-md-12 my-2'>
                                    <div className='whitebox' id="Security">
                                     
                                        <p className='boxhead graytxt'><b>Landmark Income</b></p>
                                       <ul className='security mt-4'>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Total Earned Income</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{((UserInfo?.userInfo?.landmarkIncome)/10**18).toFixed(5)}</button>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Total Direct Referred Users</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{((UserInfo?.userInfo?.directCount))}</button>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Eligiblity</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme' > { UserInfo?.userInfo?.directCount > parseFloat(landmarkeligibility) && UserInfo?.userInfo?.currentPlan > 0  ? (UserInfo?.userInfo?.directCount > parseFloat(superlandmarkeligibility) ? "SUPER ELIGIBLE" : "ELIGIBLE" ) : "NOT ELIGIBLE" } </button>
                                                </div>
                                            </li>
                                          
                                        </ul>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-12 my-2'>
                                    <div className='whitebox' id="Security">
                                     
                                        <p className='boxhead graytxt'><b>Royal Income</b></p>
                                       <ul className='security mt-4'>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Total Eligible Income in Royal Pool</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{UserInfo?.userInfo?.lastEarnedRoyal.length > 1 ? (parseFloat(UserInfo?.userInfo?.lastEarnedRoyal[0])/10**18) + (parseFloat(UserInfo?.userInfo?.lastEarnedRoyal[1])/10**18) : "0"}</button>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Current Total Income on Left</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{(parseFloat(UserInfo?.userInfo?.royalIncome[0] || 0)/10**18).toFixed(5)}</button>
                                                </div>
                                            </li>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>Current Total Income on Right</h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{(parseFloat(UserInfo?.userInfo?.royalIncome[1] || 0)/10**18).toFixed(5)}</button>
                                                </div>
                                            </li>
                                          
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 my-2'>
                                    <div className='whitebox' id="Security">
                                     
                                        <p className='boxhead graytxt'><b>Mining Benefits</b></p>
                                        <ul className='security mt-4'>
                                            <li>
                                                <img src={usertick} />
            
                                                {UserInfo?.userInfo?.yetToMint.length > 1 &&  UserInfo?.userInfo?.yetToMint[0] ?
                                                <div>
                                                    <h5 className='mb-1'>Available balance out of ledger balance </h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>Basic : {(((parseFloat(UserInfo?.userInfo?.TotalToMint[0] || 0)/10**18) - (parseFloat(UserInfo?.userInfo?.yetToMint[0] ||0)/10**18)).toFixed(5) ||0)} Out of  {(parseFloat(UserInfo?.userInfo?.TotalToMint[0] || 0)/10**18).toFixed(5)||0}</button> &nbsp;&nbsp;
                                                  <button className='btn btn-theme px-2 badge badge-theme'>Prime : { (((parseFloat(UserInfo?.userInfo?.TotalToMint[1] || 0)/10**18) - (parseFloat(UserInfo?.userInfo?.TotalToMint[1] ||0)/10**18)).toFixed(5)||0)} Out of { (parseFloat(UserInfo?.userInfo?.TotalToMint[1] ||0)/10**18).toFixed(5) ||0}</button>
                                                </div> :<div>
                                                  
                                                    <h5 className='mb-1'>Available balance out of ledger balance </h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{ ((( parseFloat(UserInfo?.userInfo?.TotalToMint[UserInfo?.userInfo?.currentPlan] || 0)/10**18) - (parseFloat(UserInfo?.userInfo?.yetToMint[UserInfo?.userInfo?.currentPlan] || 0) / 10**18)).toFixed(5) ||0)} Out of {(parseFloat(UserInfo?.userInfo?.TotalToMint[UserInfo?.userInfo?.currentPlan] || 0) / 10**18).toFixed(5)||0}</button>
                                                </div>
                                                }
                                            </li>
                                            
                                           
                                           
                                          
                                        </ul>
                                    </div>
                                </div>


                                <div className='col-lg-6 col-md-6 my-2'>
                                    <div className='whitebox' id="Security">
                                     
                                        <p className='boxhead graytxt'><b>Daily Mining</b></p>
                                        <ul className='security mt-4'>
                                            <li>
                                                <img src={usertick} />
            
                                              
                                                <div>
                                                    <h5 className='mb-1'>Minted On last 24hrs </h5>
                                                  <button className='btn btn-theme px-2 badge badge-theme'>{parseFloat(DailyLimit / 10**18) ||0}</button> &nbsp;&nbsp;
                                                  {/* <button className='btn btn-theme px-2 badge badge-theme'>Prime : { ((parseFloat(UserInfo?.userInfo?.TotalToMint[1])/10**18) - (parseFloat(UserInfo?.userInfo?.TotalToMint[1])/10**18).toFixed(5)||0).toFixed(5)} Out of { (parseFloat(UserInfo?.userInfo?.TotalToMint[1])/10**18).toFixed(5) ||0}</button> */}
                                                </div> 
                                            </li>
                                            
                                           
                                           
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 mb-5'>
                                <div className='col-md-12'>
                                    <div className='whitebox' id="refferal">
                                        <Scrollbars style={{height: 60}}>
                                            <div className='reffid' id="referralid">
                                                <span className='bluetxt'>Refferal Link</span>
                                                <span>{`${frontendurl}/${UserInfo?.userInfo?.userId}`}  <CopyToClipboard text={`${frontendurl}/${UserInfo?.userInfo?.userId}`} onCopy={() => copyText('Invite Link', `${`${frontendurl}/${UserInfo?.userInfo?.userId}`}`)}>
                                                <Button variant='link'><span className='fa fa-copy'></span></Button>
                                                </CopyToClipboard></span>
                                                </div>
                                              
                                        </Scrollbars>
                                    </div>
                                </div>
                                
                            </div>
                       
                   
                </div>
        </>
    )
}

export default Income