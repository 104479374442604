import React, { useEffect, useState } from "react";

import { Modal,Button, Form } from "react-bootstrap";

import { backurl } from "../../../config/env";
import profimg from '../../../Assets/Images/profile.png'
import {UpdateAssetSave} from "../../../Hooks/UseBackendAction/UseCreateUser.js"

const UpdateAsset = (props) =>{

    const[updateAsset, setupdateAsset] = useState(true)
    const [tokenId,settokenId] = useState(props?.record?.tokenId)
    const [tokenAddress,settokenAddress] = useState(props?.record?.tokenAddress)
    const [tokenname,settokenname] = useState(props?.record?.name)
    const [tokensymbol,settokensymbol] = useState(props?.record?.symbol)
    // const [imagepath, set_imagepath] = useState(props?.record?.imagepath)
    const [imagepath, set_imagepath] = useState("")
    const [file, set_file] = useState({})
    const [ImageURL, setImageURL] = useState("")
    const [image, set_image] = useState(props.record.logoURI)
    const [price,setprice] = useState(props?.record?.price)
   


    const onSubmit = async () => {

        const settingData = {
            
            tokenId:tokenId,
            tokenAddress:tokenAddress,
            name: tokenname,
            symbol:tokensymbol,
            price:price,
            file: file!={} ? file : imagepath,
          };
         
          await UpdateAssetSave(settingData)
         
     };
 

     const ImageUpload = event => {
        var name = event.target.value;
        set_file(event.target.files[0]);
        set_imagepath(name);
        var url = URL.createObjectURL(event.target.files[0])
        setImageURL(url)
       
    };

    return(
        <div className="signinmodal editprofile">
                
            <Modal show={updateAsset} id="assetupdate"size="md" className="br-15 thememodal">
             
                <Modal.Body className="p-0 br-15">
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                    <div className="d-flex bgblue align-items-center br-15 editprof">
                       
                        <div className=" br-15 bg-white pt-5 w-full h-44 small-input noshadow">
                            <div className="px-4">
                                <h4 className="themeclr fw-600">Update Token</h4>
                                {/* <p>Lorem hg yeg e etyegt</p> */}
                            </div>
                       
                            <hr className="themehr" />


                            <div className=" p-4">
                               
                                
                                <div className="d-lg-flex jc-between">
                                    <Form.Group className="mb-3 me-3 w-full" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Asset Address</Form.Label>
                                        <Form.Control type="text" 
                                        placeholder="" 
                                        required
                                        value={tokenAddress}
                                        onChange={(e) => { settokenAddress(e.target.value) }}/>
                                    </Form.Group>
                                   
                                </div>
                                <div className="d-lg-flex jc-between">
                                    <Form.Group className="mb-3 me-3 w-full" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Asset Symbol</Form.Label>
                                        <Form.Control type="text" 
                                        placeholder=""
                                        required
                                        value={tokensymbol}
                                        onChange={(e) => { settokensymbol(e.target.value) }} />
                                    </Form.Group>
                                    
                                </div>
                                <div className="d-lg-flex jc-between">
                                    <Form.Group className="mb-3 me-3 w-full" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Asset Name</Form.Label>
                                        <Form.Control type="text" 
                                        placeholder=""
                                        required
                                        value={tokenname}
                                        onChange={(e) => { settokenname(e.target.value) }} />
                                    </Form.Group>
                                    
                                </div>
                                {/* <div className="d-lg-flex jc-between">
                                    <Form.Group className="mb-3 me-3 w-full" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Asset Image</Form.Label>
                                        <Form.Control type="file" 
                                        placeholder=""
                                        required
                                        value={imagepath}
                                        onChange={ImageUpload} />
                                    </Form.Group>
                                    
                                </div> */}
                                <div className="d-lg-flex jc-between">
                                    <Form.Group className="mb-3 me-3 w-full" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Asset Price</Form.Label>
                                        <Form.Control type="text" 
                                        placeholder=""
                                        required
                                        value={price}
                                        onChange={(e) => { setprice(e.target.value) }} />
                                    </Form.Group>
                                    
                                </div>
                                <p>Update Assert Image</p>
                                 <div className="editimg mb-4">
                                 {ImageURL && ImageURL.length > 0 ?
                                        <img src={ImageURL} /> :  <img src={backurl+`/Images/${image}`} />
                                    }
                                    <div className="fileupload">
                                        <span className="fa fa-pencil" ></span>
                                        <input type="file"
                                        required
                                        accept=".jpg,.jpeg,.png" 
                                            onChange={ImageUpload}
                                             />
                                     </div>
                                </div>
                              

                            
                               

                            <div className="text-end mt-4">
                                <button className="btn btn-theme" onClick={() => onSubmit()}>Update</button>
                            </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}


export default UpdateAsset