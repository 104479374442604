import React,{useState,useEffect} from "react";
import DataTable from 'react-data-table-component'
import {InputGroup, Form} from 'react-bootstrap'
import { getContactinfo } from "../../Hooks/UseBackendAction/UseGetController";
import {searchedValue} from "../../Hooks/UseValidation"

import { propTypes } from "react-bootstrap/esm/Image";



const ViewUser = () =>{

  const [records, setRecords] = useState([])
  
 

  useEffect(() => {
    getData();
}, [])




const getData = async () => {
  var data = await getContactinfo();
  setRecords(data.success ? data.data : [])
};
    const columns = [
      {
        name: "S.No",
        selector: (data,index) => index+1,
        sortable: true
      },
        {
          name: "Name",
          selector: (data) => data.name,
          sortable: true
        },
        {
          name: "Email",
          selector: (data) => data.email,
          sortable: true
        },
        
        {
          name: "Phone No",
          selector: (data) => data.phoneNo,
          sortable: true
        },
        {
          name: "Country",
          selector: (data) => data.country,
          sortable: true
        },
        {
          name: "Comments",
          selector: (data) => <span className="noshrink">{data.comments}</span>,
          sortable: true,
        },
       
        
          
          ]
    
 
    return(
     
        <div className="viewuser">
         
              <div className='container container-theme'>
                <div className="d-flex jc-between small-input noshadow">
                    <h4 className="flex-shrink-0 mb-0">View Contact Us</h4>
                   
                    
                  
                </div>
               
              
                <div className="mt-4 viewdata">
                    <DataTable  columns={columns} data={records} pagination/>
                </div>
            </div>
        </div> 
    )
}

export default ViewUser