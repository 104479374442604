import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Form, Dropdown } from 'react-bootstrap'
import TransferModal from '../Modals/TransferModal';
import { UseTransfer } from "../../Hooks/UseBackendAction/UseTranfer"
import { useDispatch, useSelector } from "react-redux";

import { getTokeninfo, UsergetInfobyname ,getsettinginfo} from "../../Hooks/UseBackendAction/UseGetController"

import coin1 from '../../Assets/Images/coin1.png'
import coin2 from '../../Assets/Images/coin2.png'
import coin3 from '../../Assets/Images/coin3.png'
import Withdrawpwd from '../Modals/WithdrawpwdModal';
import GoogleAuth from '../Modals/GoogleAuthModal';
import { backurl } from '../../config/env';


const TwoFactorAuth = () => {
    const [transfer, setTransfer] = useState(false)
    const [From, setFrom] = useState(sessionStorage.getItem("userId"))
    const [To, setTo] = useState("")
    const [Amount, setAmount] = useState(0)
    const [Token, setToken] = useState("")
    const [withdrawToken, setwithdrawToken] = useState("")
    const [withdrawamount, setwithdrawamount] = useState(0)
    const [tokeninfo, setTokeninfo] = useState([])
    const [userWithdrawpassword, setuserWithdrawpassword] = useState("")
    const dispatch = useDispatch()
    const [FeeAmount, setFeeAmount] = useState(0)
    const [UserName, setUserName] = useState(sessionStorage.getItem("username") || localStorage.getItem("username"))
    // const [userBalance, setuserBalance] = useState("0")
    const [withdrawpwd, setWithdrawpwd] = useState(false)
    const [googleAuth, setGoogleAuth] = useState(false)
    const [userBalance,setuserBalance] = useState([])
    const [balance,setbalance] = useState(0);

    useEffect(() => {
        gettokeninfo();

    }, [])
    const onOtpSubmit = async (e) => {

        e.preventDefault();

        const amountTransfer = {

            token: Token,
            from: From,
            to: To,
            amount: Amount * 10 ** 18

        };
        dispatch(UseTransfer(amountTransfer))
    };

    const gettokeninfo = async () => {
        var UserInfo = await UsergetInfobyname(UserName);
        if (UserInfo.success === true) {
            setuserWithdrawpassword(UserInfo.data.withdrawPassword)
            setuserBalance(UserInfo.data.balance)
            
            const tokeninfo = await getTokeninfo();

            setTokeninfo(tokeninfo.data)
            const feeAmount = await getsettinginfo();
            setFeeAmount(Number(feeAmount.data[0].settingvalue))
            
        }
    }

    const getbalance = (value) =>{

        
        const balance =  userBalance.find((data,i)=>{return (i  == value)?data:0});
      
        if(balance > 0 ) {
            setbalance(Number (balance) / 10 ** 18)
        }else{
            setbalance(0)   
        }
       

    }

    return (
        <>
            {withdrawpwd && <Withdrawpwd token={withdrawToken} to={From} withdrawamount={withdrawamount} onDismiss={() => setWithdrawpwd(false)} />}
            {transfer && <TransferModal onDismiss={() => setTransfer(false)} />}
            {googleAuth && <GoogleAuth onDismiss={() => setGoogleAuth(false)} />}
            <div className='container container-theme'>

                <h5 className='fw-400 bluetxt'><span className='fw-700'>Transfer</span></h5>
                <div className='row mb-5'>
                    <div className=' col-lg-8'>
                        <div className='whitebox profile'>
                            <Scrollbars style={{ height: 500 }}>
                                <h5 className='graytxt pt-4 fw-600 f-18'>Transfer</h5>
                                <hr />
                                <p className='mb-4'>Available Balance : <b>{parseFloat(balance)} {Token}</b></p>
                                <form onSubmit={onOtpSubmit} className='small-input noshadow'>
                                    <div className='d-md-flex flex-1'>
                                        <div className='me-md-4'>
                                            <label>Select Currency</label>
                                            <Form.Select aria-label="Default select example"
                                                required
                                                value={Token}

                                                onChange={(e) => { setToken(e.target.value);
                                                    var index = e.target.selectedIndex;
                                                    var optionElement = e.target.childNodes[index]
                                                    var option =  optionElement.getAttribute('data-id');
                                                    getbalance(option) }}>
                                                <option>Select Currency</option>
                                                {tokeninfo && tokeninfo.map((item) =>

                                                    <option value={item.symbol} data-id={item.tokenId}>{item.name}</option>
                                                )}

                                            </Form.Select>
                                        </div>
                                        <div className='mt-md-0 mt-3'>
                                            <label>Amount</label>
                                            <Form.Control
                                                required
                                                value={Amount}
                                                onChange={(e) => { setAmount(e.target.value) }}
                                                placeholder="Enter TMC Account"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />

                                        </div>
                                    </div>

                                    <label className='mt-3'>User ID</label>
                                    <Form.Control
                                        placeholder="Enter User Id"
                                        required
                                        value={To}
                                        onChange={(e) => { setTo(e.target.value) }}
                                        aria-label="Username" />


                                    <div className='mt-5'>
                                        <label className='d-flex jc-between'><span></span><span>Fees: {FeeAmount} TMC</span></label>
                                        <p className='d-sm-flex inputparg  mb-0'>
                                            <span>Available Balance</span>
                                            <span>{parseFloat(balance)} {Token}</span>
                                        </p>
                                        <p className='d-flex jc-between f-10-420 f-14 px-3'>
                                            {/* <span>Min Amount: 10 TMC</span> */}
                                            {/* <span>Max Amount: 25000 TMC</span> */}
                                        </p>
                                    </div>
                                    {/* <button className='btn btn-theme upper mt-3' onClick={() => setTransfer(true)}>Transfer</button> */}
                                    {parseFloat(balance) > 0 && Amount > 0 ?
                                    <button className='btn btn-theme upper mt-3'>Transfer</button> :
                                    <button disabled  className='btn btn-theme upper mt-3 yellowbtn'>Transfer</button>}
                                    <p className='f-14 mt-2'>*Note: Please cross check all details before initiate transfer</p>
                                </form>

                            </Scrollbars>
                        </div>
                    </div>
                    <div className=' col-lg-4'>
                        <div className='whitebox small-input'>
                            <Scrollbars style={{ height: 500 }}>
                                <h5 className='graytxt pt-4 fw-600 f-18'>Withdraw</h5>
                                <hr />

                                <div className=''>

                                    <div>
                                        <label>Select Currency</label>
                                        <Dropdown className='selectdrop w-full'>
                                            <Dropdown.Toggle required variant="success" id="dropdown-basic">
                                                <div>{withdrawToken ? withdrawToken : "Select Currency"}</div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {tokeninfo && tokeninfo.map((item) =>

                                                    <Dropdown.Item onClick={() => setwithdrawToken(item.symbol)} > <img src={backurl + `/Images/${item.logoURI}`} /> {item.name}</Dropdown.Item>
                                                )}

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div>
                                        <div className='selectdrop w-full mt-4 noshadow'>
                                            <label>Enter Withdraw Amount</label>
                                            <Form.Control
                                                required
                                                value={withdrawamount}
                                                onChange={(e) => { setwithdrawamount(e.target.value) }}
                                                placeholder="Withdraw Amount"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />

                                        </div>
                                    </div>


                                </div>
    { withdrawamount > 0 ?

                                <button className='btn btn-theme my-5' onClick={() => setWithdrawpwd(true)}>Withdraw</button>:
                                userWithdrawpassword === " " ?
                                <button className='btn btn-theme my-5 yellowbtn' disabled >Set Withdraw Password</button> :
                                <button className='btn btn-theme my-5 yellowbtn' disabled >Withdraw</button>
    }
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TwoFactorAuth