
  // export const backurl      =    "http://localhost:2053" // local
  export const backurl   =    "https://tmcdemoapi.maticz.in" // testnet
//  export const backurl = "https://api.trademaxcoin.com" // mainnet

export const frontendurl  =     window.location.origin;
export const superAdminId = "TMC";
export const TENMINUTESINMILISECONDS = 600000;
export const TENMINUTESINSECONDS = 600;
export const ADMIN = "0x408379af9F4BE7CeEE73EC2A9c1482C89D82DBA3";
export const BUSD = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
export const USDT = "0x55d398326f99059fF775485246999027B3197955";