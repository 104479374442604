import React, { useEffect, useState } from "react";
import { Modal,Form ,InputGroup,Button} from "react-bootstrap";
import {UseWithdraw} from "../../Hooks/UseBackendAction/UseTranfer"
import {style,iconTheme,position} from '../../Hooks/UseToast'
import toast, { Toaster } from 'react-hot-toast';


const Withdrawpwd = (props) =>{
    
    const [withdrawpwd , setWithdrawpwd] = useState(true)
    const[withdrawpassword,setwithdrawpassword] = useState("")
    const[UserId , setUserId] = useState(sessionStorage.getItem("userId"))
    const [passwordeye, setpasswordeye] = useState(false)

    useEffect(() => {
       
     
   }, [])
   const showPassword = (e) => {

    var e = document.getElementById("Password");
    if (e.type === "password") {
        setpasswordeye(true)
        e.type = "text";
    } else {
        setpasswordeye(false)
        e.type = "password";
    }
};


   const onSubmit = async () => {
    if(Number(props.withdrawamount * 10 ** 18)>0){
    const UserWithdrawData = { 
       token:props.token ,
       to:props.to,
       amount: props.withdrawamount * 10 ** 18,
       password :withdrawpassword,
 };
    await UseWithdraw(UserWithdrawData)
}else{
    toast.error("Amount is Empty !",{
        position:position.position,
        style:style,
        iconTheme: iconTheme,
      })
}  
};
  
    return(
        <div className="signinmodal ">
            <Modal centered show={withdrawpwd}  size="md" className="thememodal  ">
              <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                <Modal.Body className="p-0">
                    <div className=" d-flex withdrawpass">
                        <div className="text-center leftcont px-3 py-5 bgblue" style={{borderRadius:"15px 0 0 15px"}}>
                            <h5 className="text-white">Withdraw password</h5>
                            {/* <p className="text-white">Lorem hg yeg e etyegt</p> */}
                            <span className="fa fa-lock text-white mb-0"></span>
                        </div>
                        <div className="px-3 br-15 py-5 bg-white rightcont">
                                <p className="pt-4">Enter withdraw Password </p>
                                <InputGroup className="mt-3 noshadow" controlId="exampleForm.ControlInput1">
                                <Form.Control 
                                value={withdrawpassword}
                                required
                                id="Password"
                                type="password"
                                placeholder="Withdraw Password"
                                onChange={(e) => { setwithdrawpassword(e.target.value) }}/>
                                    {!passwordeye ? 
                                              <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash " onClick={showPassword}></Button>:
                                        <Button variant="outline-secondary" id="button-addon" className="fa fa-eye " onClick={showPassword}></Button>}
                                         </InputGroup>
                                <div className="text-end">
                                     <button className="btn btn-theme mb-3 mt-5" onClick={()=>onSubmit()}>Submit</button>
                                </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default Withdrawpwd