import React, { useState, useEffect } from 'react';
import icon2 from '../../Assets/Images/user.svg'
import icon3 from '../../Assets/Images/exchange.svg'
import icon4 from '../../Assets/Images/settings.png'




const AdminSidebar = (props) =>{
    const[active, setActive] = useState(0)
    const [newdata, setnewdata] = useState(0)
    const Menus = (data) =>{
        props.Menu(data);
       
    } 



  const img1 =  <img src={icon2} /> 



 const mobilemenu = (data) =>{
    const classes = document.getElementById("sidebar").classList
    const contclasses = document.getElementById("cont").classList
    if((window.innerWidth < 576 )){
        props.newfunc()
    }

    return ( (window.innerWidth < 576 ) ? <>{classes.add("expand")}{classes.remove("compress")}</> : 
   "",

    (window.innerWidth < 576 ) ? <>{contclasses.add("compress")}{contclasses.remove("expand")}</> : 
   ""
    )


    

 }
    return(
        <div id="sidebar" className={!props.data ? "expand" : "compress"}>
            <div className='sidebar-scroll'>
            <ul>
                <li onClick={() => {Menus(0);  mobilemenu();setActive(0)}} className={active == 0 ? "active" : ""}>
                     <img src={icon2} /> <span>View User</span>
                </li>
         
                <li onClick={() => { Menus(1);  mobilemenu();setActive(1)}}  className={active == 1 ? "active" : ""}>
                     <img src={icon4} /> <span>Settings</span>
                </li>
                <li onClick={() => { Menus(2);  mobilemenu();setActive(2)}}  className={active == 2 ? "active" : ""}>
                     <img src={icon3} /> <span>View Asset</span>
                </li>
                <li onClick={() => { Menus(3);  mobilemenu();setActive(3)}}  className={active == 3 ? "active" : ""}>
                     <img src={icon3} /> <span>View Contact</span>
                </li>
                <li onClick={() => { Menus(4);  mobilemenu();setActive(4)}}  className={active == 4 ? "active" : ""}>
                     <img src={icon3} /> <span>View Subscriber</span>
                </li>
            </ul>
            </div>
        </div>
    )
}

export default AdminSidebar