import React, { useEffect, useState } from "react";
import { Modal,Form ,InputGroup, Button} from "react-bootstrap";
import{sleep} from "../../Hooks/UseBackendAction/UseGetController"
import {UseWithdrawForgotPassword} from "../../Hooks/UseBackendAction/UseCreateUser.js"
import {validatewithdrawForgotPassword} from '../../Hooks/UseValidation';
import { useNavigate } from 'react-router-dom';


const NewPassword = (props) =>{
    
    const [Withdrawpwd , setWithdrawpwd] = useState(true)
    const [Password,setPassword] = useState("")
    const [ConfirmPassword,setConfirmPassword] = useState("")
    const [Email, setEmail] = useState(localStorage.getItem("forgotEmail"))
    const [Cpasswordeye, setCpasswordeye] = useState(false)
    const [errors, seterrors] = useState({})
    const navigate = useNavigate();
    
    const [passwordeye, setpasswordeye] = useState(false)
    const showPassword = (e) => {

        var e = document.getElementById("Password");
        if (e.type === "password") {
            setpasswordeye(true)
            e.type = "text";
        } else {
            setpasswordeye(false)
            e.type = "password";
        }
    };

    const showConfirmPassword = (e) => {

        var e = document.getElementById("ConfirmPassword");
        if (e.type === "password") {
            setCpasswordeye(true)
            e.type = "text";
        } else {
            setCpasswordeye(false)
            e.type = "password";
        }
    }

    const userForgotpassword = async(data) =>{
        await UseWithdrawForgotPassword(data)
        await sleep(1000) 
       
    }
    const onSubmit = async () => {

        
        const userData = {
            user_name: Email,
            withdrawPassword: Password,
            confirmpassword: ConfirmPassword,
            otp:props.otp
      
        };

        const uservalidateData = {
            
            withdrawPassword: Password,
            confirmpassword: ConfirmPassword,
            
      
        };

        const { errors, isValid } = validatewithdrawForgotPassword(uservalidateData);
        if (!isValid) {
           
            seterrors(errors)
        }
        else {
           
             await userForgotpassword(userData)
           
         }
      
    };

    return(
        <div className="signinmodal ">
            <Modal id="newpasswordmodal" show={Withdrawpwd} centered size="sm" className="thememodal paystatus ">
              <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                <Modal.Body className="p-0 newpassword">
                    <div className="">
                     
                        <div className="px-3 br-15 py-5 bg-white rightcont">
                            <h4 className="pt-4 mb-4">Forgot Withdraw password</h4>
                            <div className="mb-3 me-3 w-full small-input">
                            <p>Enter Password</p>
                                    <InputGroup>
                                        <Form.Control
                                            type="password"
                                            required
                                            aria-label="Username"
                                            placeholder="New Password"
                                            aria-required
                                            id="Password"
                                            onChange={(e) => { setPassword(e.target.value) }}
                                            aria-describedby="basic-addon1" />
                                        {!passwordeye ? 
                                              <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showPassword}></Button>:
                                        <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showPassword}></Button>}
                                    </InputGroup>
                                    <span className="text-danger f-12 mb-3 d-block text-left">{errors.password}</span>
                                </div>
                                <div className="mb-3 me-3 w-full small-input">
                                <p>Enter Confirm Password</p>
                                    <InputGroup>
                                        <Form.Control
                                        required
                                            type="password"
                                            aria-label="Username"
                                            placeholder="Confirm Password"
                                            aria-required
                                            id="ConfirmPassword"
                                            onChange={(e) => { setConfirmPassword(e.target.value) }}
                                            aria-describedby="basic-addon1" />
                                             {!Cpasswordeye ? 
                                              <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showConfirmPassword}></Button>:
                                        <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showConfirmPassword}></Button>}
                                    </InputGroup>
                                    <span className="text-danger f-12 mb-3 d-block text-left">{errors.confirmpassword}</span>
                                </div>
                                <div className="text-end">
                                    <button className="btn btn-theme mt-4" onClick={()=>onSubmit()}>Reset</button>
                                </div>
                            
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default NewPassword