import Web3 from "web3"
import WalletConnectProvider from "@walletconnect/web3-provider";
import toast, { Toaster } from 'react-hot-toast';
import { erc20 } from "../config/erc20"

export const CURRENT_RPC_URL = ()=>{
    return "https://bsc-dataseed1.binance.org/";
}

export const CURRENT_CHAIN_ID = ()=>{
    return 56;
}

export const UseWeb3 = async() =>{
  
    const RPC_URL =  CURRENT_RPC_URL();
    const CHAIN_ID = CURRENT_CHAIN_ID();
   const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
   let web3;
   
     if(localStorage.getItem("walletconnect")!=null){
       const RPC_DATA = {};
       RPC_DATA[CHAIN_ID] = RPC_URL
       const ethereum = new WalletConnectProvider({
           rpc: RPC_DATA,
           network: 'binance',
           chainId: CHAIN_ID,
           // infuraId: "69de03b5c7194095980c9233f9cf71df",
       }
       );
   
       await ethereum.enable()
       web3 = new Web3(ethereum);
   
     //  return web3;
     }else if(localStorage.getItem("accountInfo")!=null){
      web3 = new Web3(window.ethereum);
    //   return web3;
     }else{
      web3 = new Web3(httpProvider);
    //   return web3;
     }
     const chainIds = await web3.eth.getChainId();
     if(parseInt(chainIds) != parseInt(CHAIN_ID)){
           const data = await SwitchChain();
           if(data){
             window.location.reload();
           }
       }
   
       console.log("Network URL : ",web3.currentProvider)
       let accounts = await web3.eth.getAccounts();
        web3.eth.defaultAccount = accounts[0]
   
     return web3;
     
   }
   
   export const UseProvider = async () =>{
     const RPC_URL =  CURRENT_RPC_URL();
     const CHAIN_ID = CURRENT_CHAIN_ID();
     if(localStorage.getItem("walletconnect")!=null){
       const RPC_DATA = {};
       RPC_DATA[CHAIN_ID] = RPC_URL
       const ethereum = new WalletConnectProvider({
           rpc: RPC_DATA,
           network: 'binance',
           chainId: CHAIN_ID,
           // infuraId: "69de03b5c7194095980c9233f9cf71df",
       }
       );
    
        await ethereum.enable()
       
    
        return ethereum;
      }else if(localStorage.getItem("accountInfo")!=null){
        return window.ethereum;
      }
   }


   const SwitchChain = async()=>{
    const RPC_URL =  CURRENT_RPC_URL();
    const CHAIN_ID = CURRENT_CHAIN_ID();
       // Check if MetaMask is installed
   // MetaMask injects the global API into window.ethereum
   const hexString = CHAIN_ID.toString(16);
   if (window.ethereum) {
      try {
        // check if the chain to connect to is installed
        
        const data =  window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x"+hexString }], // chainId must be in hexadecimal numbers
        });
        await toast.promise(data, {
          loading: 'Switching Network ...',
          success: 'Network Switched Successfully',
          error: 'Error ! When Switching Network',
      }, {
          style: {
          minWidth: '300px',
          minHeight: '55px'
          }
      });
       return true;
      } catch (error) {
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error.code === 4902) {
          try {
            const data =  window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: "0x"+hexString,
                  rpcUrl: RPC_URL,
                },
              ],
            });
            await toast.promise(data, {
              loading: 'Switching Network ...',
              success: 'Network Switched Successfully',
              error: 'Error ! When Switching Network',
          }, {
              style: {
              minWidth: '300px',
              minHeight: '55px'
              }
          });
            return true;
          } catch (addError) {
            console.error(addError);
            toast.error(`Error : ${addError}`,
        {
            style: {
            minWidth: '300px',
            minHeight: '55px'
            }
        });
          }
        }
        console.error(error);
      }
    } else {
      // if no window.ethereum then MetaMask is not installed
      console.log('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
      return false;
      
    } 
  }


 export const transferToken = async(contract,to,amount)=>{
    const web3 = await UseWeb3();
    const token = new web3.eth.Contract(erc20, contract);
    var balance = await token.methods.balanceOf(web3.eth.defaultAccount).call();
    var symbol = await token.methods.symbol().call();
    var bnb = await web3.eth.getBalance(web3.eth.defaultAccount); 
    if(parseInt(balance) < parseFloat(amount) * 10 ** 18){
       return toast.error(`Insufficient ${symbol} Balance !`,
        {
            style: {
            minWidth: '300px',
            minHeight: '55px'
            }
        });
    }
    if(parseFloat(bnb) <= 0){
       return toast.error(`Insufficient BNB Balance !`,
        {
            style: {
            minWidth: '300px',
            minHeight: '55px'
            }
        });
    }

    const data = token.methods.transfer(to,web3.utils.toWei(amount, 'ether')).send({ from: localStorage.getItem('accountInfo') })
    await toast.promise(data, {
        loading: 'Depositing ...',
        success: 'Deposited Successfully',
        error: 'Error ! When Depositing',
    }, {
        style: {
        minWidth: '300px',
        minHeight: '55px'
        }
    });
      return true;
  }


  export const transferBNB = async(to,amount)=>{
    const web3 = await UseWeb3();
    var bnb = await web3.eth.getBalance(web3.eth.defaultAccount); 
    
    if(parseFloat(bnb) <= 0){
       return toast.error(`Insufficient BNB Balance !`,
        {
            style: {
            minWidth: '300px',
            minHeight: '55px'
            }
        });
    }

    const data =  web3.eth.sendTransaction({
        to: to,
        value: web3.utils.toWei(amount, 'ether')
      });
    await toast.promise(data, {
        loading: 'Depositing ...',
        success: 'Deposited Successfully',
        error: 'Error ! When Depositing',
    }, {
        style: {
        minWidth: '300px',
        minHeight: '55px'
        }
    });
      return true;
  }