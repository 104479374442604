import React, { useState,useEffect } from 'react';
import {InputGroup,Form,OverlayTrigger,Button,Popover} from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import logo from '../../Assets/Images/logo.svg'
import burger from '../../Assets/Images/burger.png'
import prof from '../../Assets/Images/prof.png'
import coin1 from '../../Assets/Images/coin3.png'
import {style,iconTheme,position} from '../../Hooks/UseToast'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {logoutUser} from "../../Hooks/UseBackendAction/UseAdmin"

const AdminHeader = (props) =>{
    const[sidebar, setSidebar] = useState(true)
    const [adminusername,setadminusername] = useState(sessionStorage.getItem("adminusername"))
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const headfunc = () =>{
        setSidebar(!sidebar)
        props.newfunc(sidebar)
    }


    useEffect(() =>{
    
      if (!sessionStorage.AToken || sessionStorage.AToken == null || sessionStorage.AToken == " ") {
        toast.error("Go to Login !",{
          position:position.position,
          style:style,
          iconTheme: iconTheme,
        })
        //  sleep(1000)
        navigate("/admin/login")
      }
  },[])

  const onLogoutClick = async() => {
   
  
    dispatch(logoutUser());
  };
    return(
        <div id="header" className='adminhead'>
            <div className= {sidebar ? 'd-flex jc-between align-items-center hamexp' : 'd-flex jc-between align-items-center hamcomp'}>
                <div className='d-flex align-items-center'>

                    <button onClick={() => headfunc()} className="hamburger">
                        <img src={burger} />
                    </button>

                    <img src={logo} className="logo d-none-767"/>
                    <img src={coin1} className="mobilelogo d-block-767"/>

                </div>
                <div className='pe-4'>
                    <span>Hi, {adminusername} </span>
      <OverlayTrigger
          trigger="focus"
          placement="bottom"
          overlay={
            <Popover id="popover-positioned-bottom">
              <Popover.Body>
                <Button variant='link' className='nounder text-dark'onClick={()=>onLogoutClick()}>Logout</Button>
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="secondary" className='transbtn profimg'><img src={prof} /></Button>
        </OverlayTrigger>
                </div>
            </div>
        </div>
    )
}

export default AdminHeader