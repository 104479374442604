import React, { useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import dollar from '../../Assets/Images/dollar.png'
import premium from '../../Assets/Images/premium.png'
import basic from '../../Assets/Images/Basic.png'
import arrow from '../../Assets/Images/arrow.png'
import laptick from '../../Assets/Images/lap-tick.png'
import usertick from '../../Assets/Images/user-tick.png'
import coin1 from '../../Assets/Images/coin1.png'
import coin2 from '../../Assets/Images/coin2.png'
import coin3 from '../../Assets/Images/coin3.png'
import EditProf from '../Modals/EditProfModal';
import UpDocument from '../Modals/UpDocModal';
import * as GetController from "../../Hooks/UseBackendAction/UseGetController"
import { frontendurl } from '../../config/env';
import { Link } from "react-router-dom";
import Claim from '../Modals/ClaimModal';
import { backurl } from "../../config/env";
import CopyToClipboard from "react-copy-to-clipboard";
import { style, iconTheme, position } from '../../Hooks/UseToast'
import toast, { Toaster } from 'react-hot-toast';
import { Button } from 'react-bootstrap';





const Dashboard = (props) => {
    const [updocument, setUpDocument] = useState(false)
    const [editprofile, setEditProfile] = useState(false)
    const [UserId, setUserId] = useState(sessionStorage.getItem("userId"))
    const [UserName, setUserName] = useState(sessionStorage.getItem("username") || localStorage.getItem("username"))
    const [ReferrerId, setReferrerId] = useState(null)
    const [UserInfo, setUserInfo] = useState({})
    const [ReferrerInfo, setReferrerInfo] = useState({})
    const [RoyalIncome, setRoyalIncome] = useState(0)
    const [BinaryIncome, setBinaryIncome] = useState(0)
    const [tokeninfo, setTokeninfo] = useState([])
    const [userfname, setuserfname] = useState(sessionStorage.getItem("userfname"))
    const [userlname, setuserlname] = useState(sessionStorage.getItem("userlname"))
    const [active, setActive] = useState(0)
    const [coindata, setcoindata] = useState([])
    const [TotalTMC, setTotalTMC] = useState(0)
    const [KYCFileLength, setKYCFileLength] = useState(0)
    const [tmcprice, settmcprice] = useState(0)
    const [settingData, setsettingdata] = useState([])


    const [claim, setClaim] = useState(false)

    const Menus = (data) => {
        props.Menu(data)
        setActive(data)
    }

    const copyText = (a, b) => {
        toast.success("Refferal Link Copied", {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        }
        )}
        
    useEffect(() => {
        getuserData();
        // showLoader();

    }, []);
    // const showLoader = () => {
    //     document.getElementById("loader").classList.add("show")
    //        document.getElementsByTagName("body")[0].style.overflow = "hidden"
    //  }
   
    //  const hideLoader = () => {
    //    document.getElementById("loader").classList.remove("show")
    //    document.getElementsByTagName("body")[0].style.overflow = "unset"
   
    // }


    const getuserData = async () => {
        const tokeninfo = await GetController.getTokeninfo();
        var TokenPrice = await GetController.UseGEtTokenPrice(tokeninfo.data[1].symbol);
        settmcprice(TokenPrice.data)
       
        var UserInfo = await GetController.UsergetInfobyname(UserName);
        const settingData = await GetController.getsettinginfo();
        setsettingdata(settingData.data)


        if (UserInfo.success === false) {

            document.getElementById("profile").classList.add("blur")
            document.getElementById("income").classList.add("blur")
            document.getElementById("balance").classList.add("blur")
            document.getElementById("Security").classList.add("blur")
            document.getElementById("refferal").classList.add("blur")
            document.getElementById("news").classList.add("blur")
            document.getElementById("referralid").classList.add("blur")
            // hideLoader();

        }
        else {
            setUserInfo(UserInfo.data)
           
            setKYCFileLength(Number(UserInfo.data.KYCFile.length))


            document.getElementById("profile").classList.remove("blur")
            document.getElementById("income").classList.remove("blur")
            document.getElementById("balance").classList.remove("blur")
            document.getElementById("Security").classList.remove("blur")
            document.getElementById("refferal").classList.remove("blur")
            document.getElementById("news").classList.remove("blur")
            document.getElementById("referralid").classList.remove("blur")
            document.getElementById("completeprofile").style.display = "none";

            setReferrerId(UserInfo?.data?.userInfo?.referrerID)
            var ReferrerInfo = await GetController.UserreferrergetInfo(UserInfo?.data?.userInfo?.originalReferrer)
            setReferrerInfo(ReferrerInfo?.data)
            // var totalGainTMC = await GetController.UseUSDValue(UserInfo?.data?.userInfo?.gainAmount / 10 ** 18);
            // setTotalTMC(totalGainTMC.toFixed(3));

            var royalincome = await GetController.UserRoyalIncome(UserId);
            setRoyalIncome(Number(royalincome?.RoyalIncome))
            var userminingincome = await GetController.UserMining(UserId);
            var BinaryincomeValue = await GetController.UserBinaryIncome(UserId);
            setBinaryIncome(BinaryincomeValue)
            var tokenid = await Promise.all(await UserInfo?.data?.balance.map(async (item, index) => {
                var dat = await GetController.UsetokeninfoById(index)

                dat['data']['Balance'] = item
                return dat['data']

            }))


            setcoindata(tokenid)
            //  hideLoader();

        }
    };

    const EditProfile = async () => {
        setEditProfile(true)
    }


    return (
        <>

            {claim && <Claim record={UserInfo} onDismiss={() => setClaim(false)} />}
            {editprofile && <EditProf record={UserInfo} onDismiss={() => setEditProfile(false)} />}
            {updocument && <UpDocument onDismiss={() => setUpDocument(false)} />}
            <div className='container container-theme'>

                <div className="d-sm-flex jc-between">



                    <h5 className='fw-400 bluetxt'>Welcome <span className='fw-700'>{UserInfo?.user_name ? UserInfo?.user_name : UserName} !</span></h5>
                    {/* <Link to="/asset"> */}
                    <button className='btn btn-theme px-2' id="completeprofile" onClick={() => Menus(1)}>Complete profile</button>
                    {/* </Link> */}
                </div>

                <div className='row mb-5'>
                    <div className='col-xl-7 col-lg-6'>
                        <div className='whitebox profile ' id="profile">
                            <Scrollbars style={{ height: 280 }}>
                                <div className='d-flex align-items-center jc-between'>
                                    <h6 className='upper'>Profile</h6>
                                    <button className='btn btn-theme' onClick={() => EditProfile()}>Edit Profile</button>
                                </div>
                                <div>
                                    <h6 className='mb-0'>Username</h6>
                                    <p className='graytxt'>{UserInfo?.user_name}</p>
                                </div>
                                <div>
                                    <h6 className='mb-0'>E-mail Address</h6>
                                    <p className='graytxt'>{UserInfo?.email}</p>
                                </div>
                                <div>
                                    <h6 className='mb-0'>Phone</h6>
                                    <p className='graytxt'>{UserInfo?.mobile_no}</p>
                                </div>
                                <div>
                                    <h6 className='mb-0'>Referred by</h6>
                                    <p className='mb-0 f-14 graytxt'>Email: {ReferrerInfo?.email}</p>
                                    <p className='mb-0 f-14 graytxt'>Username: {ReferrerInfo?.user_name}</p>
                                </div>
                            </Scrollbars>
                        </div>
                        <div className='whitebox' id="news">
                            <p className='boxhead graytxt'>News</p>
                            <Scrollbars style={{height: 280}}>
                               
                                {/* <iframe src="https://twitframe.com/show?url=https%3A%2F%2Ftwitter.com%2Fjack%2Fstatus%2F20" width="100%" height="98%" title="description"></iframe> */}
                                <iframe src={settingData && settingData[22] && settingData[22].settingvalue} width="100%" height="98%" title="description"></iframe>
                                </Scrollbars>
                            </div>
                    </div>
                    <div className='col-xl-5 col-lg-6'>
                        <div className='whitebox' id="income">
                            <div className='d-flex jc-between mb-3'>
                                <p className='boxhead graytxt mb-0'>Referral Income </p>

                                <button className='btn btn-theme' onClick={() => setClaim(true)}>Claim</button>
                            </div>
                            <div className='d-flex jc-around text-center reffincome'>
                                <div>
                                    <p className='graytxt mb-0'>Binary Income</p>
                                    <img src={dollar} />
                                    <h5 className='aqua'>$ {((parseFloat(UserInfo?.userInfo?.binaryIncome) * tmcprice) / 10 ** 18).toFixed(5)}</h5>
                                    <p className='graytxt f-14'>TMC (In USDT)</p>
                                </div>
                                {/* <div>
                                        <p className='graytxt mb-0'>Royal Income</p>
                                        <img src={arrow} />
                                        <h5 className='aqua'>{RoyalIncome}</h5>
                                        <p className='graytxt f-14'>TMC coins</p>
                                    </div> */}
                                <div>
                                    <p className='graytxt mb-0'>Total Income</p>
                                    <img src={arrow} />
                                    <h5 className='aqua'>$ {UserInfo.userInfo?.gainAmount / 10 ** 18}</h5>
                                    <p className='graytxt f-14'>USD coins</p>
                                </div>
                                <div>
                                    <p className='graytxt mb-0'>Your Plan</p>
                                    {UserInfo?.userInfo?.currentPlan == "0" ?
                                        <img src={basic} /> :
                                        <img src={premium} />}

                                    <h5 className='aqua'>{UserInfo?.userInfo?.currentPlan == "0" ? "Basic" : "Prime"}</h5>
                                    <p className='graytxt f-14'>User</p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-8 col-md-7 col-sm-7'>
                                <div className='whitebox' id="Security">

                                    <p className='boxhead graytxt'>Security</p>
                                    <Scrollbars style={{ height: 260 }}>
                                        <ul className='security mt-4'>
                                            <li>
                                                <img src={usertick} />
                                                <div>
                                                    <h5 className='mb-1'>KYC Verification</h5>
                                                    {UserInfo.isKYCVerify == null ?
                                                        <button className='btn btn-theme px-2 badge badge-theme' onClick={() => setUpDocument(true)}>Upload</button> :

                                                        UserInfo.isKYCVerify === "false" ?

                                                            <button className='btn btn-theme px-2 badge badge-theme' onClick={() => setUpDocument(true)}>Reupload</button> :

                                                            <button className='btn btn-theme px-2 badge badge-theme'>Verified</button>
                                                    }

                                                </div>
                                            </li>
                                            <li>
                                                <img src={laptick} />
                                                <div>
                                                    <h5 className='mb-1'>2 Factor Authentication</h5>
                                                    <button className='btn btn-theme px-2 badge badge-theme'>{UserInfo.isAuth === true ? "Enabled" : "Disabled"}</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </Scrollbars>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-5 col-sm-5'>
                                <div className='whitebox' id="balance" >
                                    <p className='boxhead graytxt'>Coin Balance</p>
                                    <div className='coinbal text-center'>
                                        <Scrollbars style={{ height: 260 }} className="h84-575">
                                            <ul className='coinbal'>
                                                {coindata.map((item) =>
                                                    <li>
                                                        <p className='aqua'>{item.symbol}</p>
                                                        <img src={backurl + `/Images/${item.logoURI}`} />
                                                        <p className='aqua'>{parseFloat(item.Balance / 10 ** 18).toFixed(5) || 0}</p>
                                                    </li>
                                                )}
                                            </ul>

                                        </Scrollbars>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='whitebox' id="refferal">
                                    <Scrollbars style={{ height: 60 }}>
                                        <div className='reffid' id="referralid">
                                            <span className='bluetxt'>Refferal Link</span>
                                            <span>{`${frontendurl.substring(0, 12)}/${UserInfo?.userInfo?.userId}`}  <CopyToClipboard text={`${frontendurl}/${UserInfo?.userInfo?.userId}`} onCopy={() => copyText('invite link', `${`${frontendurl}/${UserInfo?.userInfo?.userId}`}`)}>
                                                <Button variant='link' className='px-0'><span className='fa fa-copy ms-0'></span></Button>
                                            </CopyToClipboard></span>
                                        </div>

                                    </Scrollbars>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard