import React, { useState,useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { Modal,Button, Form } from "react-bootstrap";
import Countdown from "react-countdown";
import {CheckLoginOtp,ResentOtp} from "../../Hooks/UseBackendAction/UseCreateUser.js"
import {useDispatch } from "react-redux";
import {TENMINUTESINSECONDS} from "../../config/env"
import { useNavigate } from 'react-router-dom';
import {TENMINUTESINMILISECONDS} from "../../config/env";
import axios from "axios";

const OtpModal = (props) =>{
    const[otp , setOtp] = useState(true)
    const dispatch = useDispatch()
    const [Enablebtn, setEnablebtn] = useState(false)
    const [Email,setEmail] = useState(sessionStorage.getItem("userLoginmail"))
    const [username,setusername] = useState(sessionStorage.getItem("username") || localStorage.getItem("username"))
    const [accessToken,setaccessToken] = useState(sessionStorage.getItem("Token"))
    const [counter, setCounter] = useState(TENMINUTESINSECONDS)
    const [Seconds, setseconds] = useState(0)
    const [Minutes, setminutes] = useState(0)
    const [box1,setbox1] = useState("")
    const [box2,setbox2] = useState("")
    const [box3,setbox3] = useState("")
    const [box4,setbox4] = useState("")
    const [ip, setIP] = useState('');
   



    
    useEffect(() => {
      GetIpAddress()
      if( counter == 0){
        // toast.error(`Your OTP is Expired !`,
        // {
        //     style: {
        //         minWidth: '300px',
        //         minHeight: '55px'
        //     }
        // });
          setEnablebtn(true);
       
      }
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
     
      const seconds = String(counter % 60).padStart(2, 0);
      setseconds(seconds)
      const minutes = String(Math.floor(counter / 60)).padStart(2, 0);
      setminutes(minutes)
      
      return () => 
      
           clearInterval(timer);
          

     
  }, [counter]);

  const GetIpAddress = async()=>{
    const res = await axios.get('https://geolocation-db.com/json/')
    
     setIP(res.data.IPv4)
}

  const generateCountdown = () =>{
 
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    const seconds = String(counter % 60).padStart(2, 0);
    setseconds(seconds)
    const minutes = String(Math.floor(counter / 60)).padStart(2, 0);
    setminutes(minutes)
    return () => 
    
         clearInterval(timer);
  }

    let tabChange = (val) => {
      if(3 >= val ===true){
      
        let ele = document.querySelectorAll('input.otp');
        
        if(ele[val-1].value != ''){
          ele[val].focus()
        }else if(ele[val-1].value == ''){
          ele[val-2].focus()
        }  
      } 
     }
    

      const onOtpSubmit = async (e) => {

        const Loginhistory ={
         
          loginTime:Buffer.from(String(Date.now())).toString('base64'),
          Browser:Buffer.from(window.navigator.userAgent).toString('base64'),
          ipaddress: Buffer.from(ip).toString('base64')

       }
        e.preventDefault();
      
        const UserOtpData = {
          // headers : {authorization: `Bearer ${accessToken}`},
  
          user_name: username,
          Otp: box1 + box2 + box3 + box4,
          Date:Date.now(),
          LoginHistory:Loginhistory
            
        };
       
        await CheckLoginOtp(UserOtpData)

    };


    const resentotp = async() => {

  
      const userData = {
        user_name: username,
        CreatedAt: Date.now() + TENMINUTESINMILISECONDS
          
      };
         await ResentOtp(userData)
     
     
     
  };
   

  
 
    // const[modalwidth, setModalWidth] = useState(true)
    return(
        <div className="signinmodal editprofile">
                
            <Modal centered show={otp} size="md" className="br-15 thememodal">
               
                <Modal.Body className="br-15"> 
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
               <h4><b>Login OTP</b></h4>
                <div className='otp-log'>
                    <p className='f-12'>Enter the code we've sent via Mail to <span>{Email}</span></p>

                
         
          
            
            <form action="" className="mt-5 mb-4 justify-content-center confirm">
              <input className="otp" type="text"  onChange={(e) => { setbox1(e.target.value) }} onKeyUp={() => tabChange(1)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox2(e.target.value) }} onKeyUp={() => tabChange(2)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox3(e.target.value) }} onKeyUp={() => tabChange(3)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox4(e.target.value) }} onKeyUp={() => tabChange(4)} maxlength="1" />
            </form>
           <div className="text-center">
           {Enablebtn ?
            <button className="btn btn-theme-bord me-2" onClick={async()=> {await resentotp();setCounter(TENMINUTESINSECONDS) ;generateCountdown()}}>Resend</button>
            
            
            :
            <button className="btn btn-theme-bord me-2" disabled ={true}>Resend</button>
            }
            <button className="btn btn-theme" onClick={onOtpSubmit}>Confirm</button>
           </div>
           <div className="text-center mt-3">
            <p className="pr-4"> Otp will expires in <b>  <span>{Minutes}:{Seconds}</span></b></p></div> 
           
                </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default OtpModal