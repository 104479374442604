import React,{useState,useEffect} from "react";
import DataTable from 'react-data-table-component'
import {InputGroup, Form} from 'react-bootstrap'
import { getTokeninfo } from "../../Hooks/UseBackendAction/UseGetController";
import {searchedValue} from "../../Hooks/UseValidation"
import AddAsset from "./Modals/TokenaddModal";
import UpdateAsset from "./Modals/TokenUpdateModal"
import { propTypes } from "react-bootstrap/esm/Image";
import { backurl } from "../../config/env";


const ViewUser = () =>{

  const [records, setRecords] = useState([])
  
  const[addAsset, setaddAsset] = useState(false)
  const[updateAsset, setupdateAsset] = useState(false)
  const [Currentrecords, setCurrentRecords] = useState({})

  useEffect(() => {
    getData();
}, [])




const getData = async () => {
  var data = await getTokeninfo();
  setRecords(data.data)
};
    const columns = [
      {
        name: "S.No",
        selector: (data,index) => index+1,
        sortable: true
      },
        {
          name: "Token Symbol",
          selector: (data) => data.symbol,
          sortable: true
        },
      
        {
            name: "Token Address",
           
            sortable: true,
            cell: (data) => (
              <span>

              {`${data?.tokenAddress?.substring(0, 3)}...${data?.tokenAddress?.substring(39, 42)}`} 
            </span>
            )
          },
          {
            name: "Asset Image",
           
            sortable: true,
            cell: (data) => 
            { return (
              <img
                  src={backurl + `/Images/${data.logoURI}`}
                  width={30}
                  alt='form' className="b-50"
              />
          )}
          },
          {
            name: "Action",
            button: true,
            cell: (data) => (
         
          <button
            type="button"
            class="btn btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            onClick={() => { editRecord(data); setupdateAsset(true) }}>
            Edit
          </button>
            )}
          ]
    
    const editRecord = async (record) => {
      setCurrentRecords(record)
  }
    return(
     
        <div className="viewuser">
          {addAsset && <AddAsset onDismiss={() => setaddAsset(false)} />}
          {updateAsset && <UpdateAsset record={Currentrecords} onDismiss={() => setupdateAsset(false)}/>}
              <div className='container container-theme'>
                <div className="d-flex jc-between small-input noshadow">
                    <h4 className="flex-shrink-0 mb-0">View Asset</h4>
                   
                    
                   <button className="btn btn-theme" onClick={() => setaddAsset(true)}>Add</button>
                </div>
               
              
                <div className="mt-4 viewdata">
                    <DataTable  columns={columns} data={records} pagination/>
                </div>
            </div>
        </div> 
    )
}

export default ViewUser