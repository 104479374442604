import axios from "axios";
import * as config from "../../config/env";
import { sleep } from "./UseGetController";
import {style,iconTheme,position} from '../UseToast'
import isEmpty from "is-empty";
import toast, { Toaster } from 'react-hot-toast';
import setAuthToken from "../../store/setAuthToken";

export const UseAdminLogin = (param) => {


    axios
        .post(config.backurl + "/api/admin/login", param)
        .then(async (res) => {
           
            
            if (res.status === 200 && res.data.success === true) {
                sessionStorage.setItem("AToken",res.data.accessToken)
                sessionStorage.setItem("adminusername",res.data.username)
                
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
               
                window.location.href = config.frontendurl + "/admin";

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseNewSetting = (param) => {


    axios
        .post(config.backurl + "/api/admin/new/setting/:token", param)
        .then(async (res) => {
           
            
            if (res.status === 200 && res.data.success === true) {
                
                
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
               
                window.location.href = config.frontendurl + "/admin";

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const UseUpdateSetting = (param) => {


    axios
        .post(config.backurl + "/api/admin/update/setting/:token", param)
        .then(async (res) => {
           
            
            if (res.status === 200 && res.data.success === true) {
                
                
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
               
                window.location.href = config.frontendurl + "/admin";

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};



export const logoutUser = () => () => {
  
   
    sessionStorage.removeItem("AToken")
    sessionStorage.removeItem("adminusername")
   
   
    setAuthToken(false);
    window.location.href = config.frontendurl +"/admin/login";
};