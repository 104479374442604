import React, { useEffect, useState } from "react";

import { Modal,Button, Form } from "react-bootstrap";
import { backurl } from '../../../config/env';
import {UseisKyc} from "../../../Hooks/UseBackendAction/UseCreateUser.js"

import profimg from '../../../Assets/Images/profile.png'

const Documents = (props) =>{

    const[document, setDocument] = useState(true)
    const [kycfile,setfile] = useState([])
    const [username,setusername] = useState("")
    const [isKYCVerify,setisKYCVerify] = useState(null)

    useEffect(() => {
       
        setfile(props?.record?.KYCFile)
        setusername((props?.record?.user_name))
      
        
    }, [isKYCVerify])

const onisKycSubmit = async (value) => {

        const isKycdata = { user_name: username,isKYCVerifyAuth: value };
              await UseisKyc(isKycdata)
};

    return(
        <div className="signinmodal editprofile">
                
            <Modal id="KYCfile "show={document} size="md" className="br-15 thememodal">
             
                <Modal.Body className="p-0 br-15">
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                    <div className="d-flex bgblue align-items-center br-15 editprof">
                        <div className=" br-15 bg-white pt-5 w-full h-44 small-input noshadow">

                            <div className="px-4">
                                <h4 className="themeclr fw-600">KYC Information</h4>
                                {/* <p>Lorem hg yeg e etyegt</p> */}
                            </div>
                            <hr className="themehr" />

                           <div className="px-4 docheight">
                           <table class="table text-center table-borderless ">
                            <thead>
                                <tr>
                                <th scope="col"> <h6 className="mb-0">S.No</h6></th>                             
                                <th scope="col"><h6 className="mb-0">Document Type</h6></th>
                                <th scope="col"><h6 className="mb-0">File</h6></th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                {kycfile.map((item,index) => 
                                <>
                                <td>{index + 1}</td>
                                <td>{item.type} card </td>
                                <td> <a target="blank" href={`${backurl+`/Images/${item.data}`}`} className="btn btn-theme">Preview</a></td>
                                </>
                                )}
                              
                           </tr>
                            </tbody>
                            </table>
                          
                           </div>
                          
                            <div className=" p-4">
                                <div className="text-end mt-4">
                                    <button className="btn btn-theme" onClick={() => {  onisKycSubmit(true)}}>Accept</button>
                                    <button className="btn btn-theme ms-2 f-14" onClick={() => {  onisKycSubmit(false)}}>Decline</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}


export default Documents