import React, { useState, useEffect } from 'react';


import AdminSidebar from '../Admin/AdminSidebar';
import AdminHeader from '../Admin/AdminHeader';
import ViewUser from './ViewUser';
import Settings from './Settings';
import ViewAsset from "./ViewAssest";
import ViewContact from "./ViewContact";
import ViewSubsciber from "./ViewSubscriber";


const AdminLand = (props) => {
    const[data, setData] =useState(false)
    const[option, setOption] = useState(localStorage.getItem("count"));
    
    const newfunc = () =>{
        setData(!data)
    }
    const Menu1 = (data) =>{
        setOption(data)
       
        // setOption(localStorage.getItem("count"));
        // setOption(localStorage.getItem("count"));
    }

    // useEffect(() => {
   
    //    if((performance.getEntriesByType("navigation")[0].type) == "reload") 
    //    {
    //     setOption(localStorage.getItem("count"));
    
    //    }
    //    else
    //    {
    //     setOption(localStorage.getItem("count"));
    
    //    }
    //   });
    
    return(
        <>
        <AdminHeader newfunc={() =>newfunc()}/>
        <div id='page'>
            <AdminSidebar data = {data} Menu={Menu1} newfunc={newfunc}/>
            <div id="cont" className= {data ? "expand pt-5" : "compress pt-5"}>
                { option == 0 ? <ViewUser  Menu={Menu1} /> :  
                  option == 1 ?<Settings /> : 
                  option == 2 ? <ViewAsset/> : 
                  option == 3 ? <ViewContact/> : 
                  option == 4 ? <ViewSubsciber/> : <ViewContact/>
               
                  }
                 
                

               
     
            </div>
        </div>
        </>
    )
}

export default AdminLand 